import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    conditions: {
      type: Object
    },
    gradeData: {
      type: Array
    },
    bonusData: {
      type: Array
    },
    levelData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        bonusMode: 0,
        isTighten: 1,
        //是否紧缩
        bonusTag: [],
        factorList: [],
        isAfterGrade: 0
      }
    };
  },
  mounted: function mounted() {
    if (this.conditions) {
      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }
    }
  },
  methods: {
    createFactor: function createFactor() {
      var tmp = [];

      if (this.form.bonusMode === 0) {
        for (var i in this.gradeData) {
          var item = this.gradeData[i];
          tmp.push({
            grade: item.tag,
            gradeName: item.name,
            rateList: [{
              rate: 0,
              monthPov: 0
            }]
          });
        }
      } else {
        for (var _i in this.levelData) {
          var _item = this.levelData[_i];
          tmp.push({
            grade: _item.tag,
            gradeName: _item.name,
            rateList: [{
              rate: 0,
              monthPov: 0
            }]
          });
        }
      }

      this.form.factorList = tmp;
    },
    clearFactorData: function clearFactorData() {
      this.form.factorList = [];
    },
    clickDelFactor: function clickDelFactor(index) {
      var tmp = this.form.factorList;
      tmp.splice(index, 1);
      this.form.factorList = tmp;
    },
    clickAddLayer: function clickAddLayer(index) {
      this.form.factorList[index].rateList.push({
        rate: 0,
        monthPov: 0
      });
    },
    clickDelLayer: function clickDelLayer(index, subIndex) {
      var tmp = this.form.factorList[index].rateList;
      tmp.splice(subIndex, 1);
      this.form.factorList.factorList[index] = tmp;
    },
    clickBatchSet: function clickBatchSet() {
      if (this.form.factorList.length > 0) {
        var item = this.form.factorList[0].rateList;
        var tmp = this.form.factorList;

        for (var index in tmp) {
          tmp[index].rateList = item;
        }

        this.form.factorList = this.$tempModel.deepCopy(tmp);
      }
    },
    getParams: function getParams() {
      var params = this.form;
      return this.$tempModel.deepCopy(params);
    }
  }
};