import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { sendCmd } from '@/api/apis';
export default {
  name: 'pv',
  components: {},
  data: function data() {
    return {
      value1: '',
      excelName: 'xxxx',
      isImport: false,
      numbers: [],
      groups: []
    };
  },
  mounted: function mounted() {},
  methods: {
    clickLink: function clickLink() {
      window.open("/statics_util/");
    },
    onImport: function onImport() {
      this.$refs.import.value = null; //解决change事件在同一个文件下不触发的问题

      this.$refs.import.click();
    },
    onExcelImport: function onExcelImport(event) {
      var _this = this;

      this.numbers = [];
      this.groups = [];
      var file = event.target.files[0];
      this.$import.xlsx(file).then(function (_ref) {
        var header = _ref.header,
            results = _ref.results;

        try {
          console.log('header', header);

          if (header.indexOf('会员编号') < 0 || header.indexOf('会员姓名') < 0) {
            _this.$alert('表格格式错误', '', {
              confirmButtonText: '确定'
            });

            return;
          }

          console.log('results', results);

          for (var i in results) {
            var item = results[i];

            _this.numbers.push(item['会员编号']);

            _this.groups.push(item['会员姓名']);
          }

          _this.isImport = true;
        } catch (err) {
          _this.$alert('表格格式错误', '', {
            confirmButtonText: '确定'
          });
        }
      });
    },
    create: function create(tag) {
      var _this2 = this;

      if (tag == 0) {
        if (this.excelName === '' || this.value1 === '' || !this.isImport) {
          this.$alert('请导入会员并输入查询日期', '', {
            confirmButtonText: '确定'
          });
          return;
        }

        var numbers = this.numbers.join(',');
        var groups = this.groups.join(',');
        var start = this.value1[0];
        var end = this.value1[1];
        sendCmd(['query2', '-numbers', numbers, '-groups', groups, '-start', start, '-end', end, '-name', this.excelName]).then(function () {
          _this2.$alert('执行成功，请2分钟后点击文件列表查看', '', {
            confirmButtonText: '确定'
          });
        });
      }
    }
  }
};