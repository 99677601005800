var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "margin-bottom": "5px" }, attrs: { shadow: "never" } },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _c("span", [_vm._v(_vm._s("子条件" + _vm.index))]),
          _c(
            "el-select",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: {
                size: "mini",
                filterable: "",
                placeholder: "请选择条件"
              },
              on: { change: _vm.condChange },
              model: {
                value: _vm.data.id,
                callback: function($$v) {
                  _vm.$set(_vm.data, "id", $$v)
                },
                expression: "data.id"
              }
            },
            _vm._l(_vm.conditionList, function(cond) {
              return _c("el-option", {
                key: cond.id,
                attrs: { label: cond.name, value: cond.id }
              })
            }),
            1
          ),
          _vm.hasMySelf()
            ? _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "20px" },
                  model: {
                    value: _vm.data.myself,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "myself", $$v)
                    },
                    expression: "data.myself"
                  }
                },
                [_vm._v("包含本人业绩 ")]
              )
            : _vm._e(),
          _vm.hasSolid()
            ? _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "20px" },
                  model: {
                    value: _vm.data.solid,
                    callback: function($$v) {
                      _vm.$set(_vm.data, "solid", $$v)
                    },
                    expression: "data.solid"
                  }
                },
                [_vm._v("等级是否实单 ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { float: "right" },
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.clickDelItem }
            },
            [_vm._v("删除")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-right": "10px" },
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.clickResetParams }
            },
            [_vm._v("重置参数 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { float: "right", "margin-right": "10px" },
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.clickAddCondition }
            },
            [_vm._v("添加条件 ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _vm.hasPeriod()
          ? _c(
              "div",
              { staticClass: "row" },
              [
                _c("span", { staticClass: "row-title" }, [_vm._v("周期")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100px" },
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.data.period,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "period", $$v)
                      },
                      expression: "data.period"
                    }
                  },
                  [
                    _c("el-option", { attrs: { value: 0, label: "累计" } }),
                    _c("el-option", { attrs: { value: 1, label: "周新增" } }),
                    _c("el-option", { attrs: { value: 2, label: "月新增" } })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.hasType()
          ? _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-left": "30px" } },
              [
                _c("span", { staticClass: "row-title" }, [_vm._v("单型")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "220px" },
                    attrs: { size: "mini", multiple: "" },
                    model: {
                      value: _vm.data.type,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "type", $$v)
                      },
                      expression: "data.type"
                    }
                  },
                  [
                    _c("el-option", { attrs: { value: 1, label: "注册" } }),
                    _c("el-option", { attrs: { value: 2, label: "重购" } }),
                    _c("el-option", { attrs: { value: 3, label: "升级" } })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.hasOrder()
          ? _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-left": "30px" } },
              [
                _c("span", { staticClass: "row-title" }, [_vm._v("方式")]),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100px" },
                    attrs: { size: "mini" },
                    on: { change: _vm.orderChange },
                    model: {
                      value: _vm.data.order,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "order", $$v)
                      },
                      expression: "data.order"
                    }
                  },
                  [
                    _c("el-option", { attrs: { value: 0, label: "业绩" } }),
                    _c("el-option", { attrs: { value: 1, label: "单数" } }),
                    _c("el-option", { attrs: { value: 2, label: "产品数量" } })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._l(_vm.data.params, function(param) {
        return _c(
          "el-row",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "align-items": "center",
              "margin-top": "10px"
            }
          },
          [
            _c("span", { staticStyle: { width: "100px" } }, [
              _vm._v(_vm._s(param.name))
            ]),
            _c(
              "el-input",
              {
                staticStyle: { width: "160px" },
                attrs: { size: "mini" },
                model: {
                  value: param.rename,
                  callback: function($$v) {
                    _vm.$set(param, "rename", $$v)
                  },
                  expression: "param.rename"
                }
              },
              [_c("template", { slot: "prepend" }, [_vm._v("别名")])],
              2
            ),
            _c(
              "el-select",
              {
                staticStyle: { "margin-left": "20px", width: "70px" },
                attrs: { size: "mini" },
                model: {
                  value: param.op,
                  callback: function($$v) {
                    _vm.$set(param, "op", $$v)
                  },
                  expression: "param.op"
                }
              },
              [
                _c("el-option", { attrs: { label: ">=", value: ">=" } }),
                _c("el-option", { attrs: { label: "=", value: "=" } }),
                _c("el-option", { attrs: { label: "<=", value: "<=" } }),
                _c("el-option", { attrs: { label: ">", value: ">" } }),
                _c("el-option", { attrs: { label: "<", value: "<" } })
              ],
              1
            ),
            _vm.hasGrade(param)
              ? _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      size: "mini",
                      filterable: "",
                      placeholder: "请选择等级"
                    },
                    model: {
                      value: param.value,
                      callback: function($$v) {
                        _vm.$set(param, "value", $$v)
                      },
                      expression: "param.value"
                    }
                  },
                  _vm._l(_vm.gradeList, function(grade) {
                    return _c("el-option", {
                      key: grade.tag,
                      attrs: { label: grade.name, value: grade.tag }
                    })
                  }),
                  1
                )
              : _vm.hasLevel(param)
              ? _c(
                  "el-select",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: {
                      size: "mini",
                      filterable: "",
                      placeholder: "请选择资格"
                    },
                    model: {
                      value: param.value,
                      callback: function($$v) {
                        _vm.$set(param, "value", $$v)
                      },
                      expression: "param.value"
                    }
                  },
                  _vm._l(_vm.levelList, function(level) {
                    return _c("el-option", {
                      key: level.tag,
                      attrs: { label: level.name, value: level.tag }
                    })
                  }),
                  1
                )
              : _c("el-input", {
                  staticStyle: { width: "130px", "margin-left": "20px" },
                  attrs: { size: "mini", placeholder: "请输入参数值" },
                  model: {
                    value: param.value,
                    callback: function($$v) {
                      _vm.$set(param, "value", $$v)
                    },
                    expression: "param.value"
                  }
                }),
            _c("span", { staticStyle: { "margin-left": "20px" } }, [
              _vm._v(_vm._s(param.unit))
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }