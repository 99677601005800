import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    productList: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      productCode: '',
      productNum: 1,
      isOpen: false,
      tableData: []
    };
  },
  mounted: function mounted() {},
  methods: {
    getParams: function getParams() {
      return this.tableData;
    },
    reloadData: function reloadData(table) {
      this.tableData = table;
    },
    productByCode: function productByCode(code) {
      for (var i in this.productList) {
        if (this.productList[i].code === code) {
          return this.productList[i];
        }
      }

      return null;
    },
    addProduct: function addProduct() {
      var tmp = this.tableData;
      var item = this.productByCode(this.productCode);

      for (var i in tmp) {
        if (tmp[i].code === item.code && tmp[i].store_name === item.store_name) {
          this.$message({
            message: '商品已添加',
            type: 'warning'
          });
          return;
        }
      }

      tmp.push({
        id: item.id,
        store_name: item.store_name,
        product_num: this.productNum,
        code: item.code
      });
      this.tableData = tmp;
    },
    reload: function reload() {
      this.tableData = [];
    },
    clickDel: function clickDel(index, row) {
      this.tableData.splice(index, 1);
    }
  }
};