import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b4a964f&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b4a964f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b4a964f')) {
      api.createRecord('1b4a964f', component.options)
    } else {
      api.reload('1b4a964f', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=1b4a964f&scoped=true&", function () {
      api.rerender('1b4a964f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/sysconfig/systemCreator/systemConfig/resaleTaxConfig/index.vue"
export default component.exports