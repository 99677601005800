import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../../conditionCreator';
import { productList } from '@/api/apis';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    conditions: {
      type: Object
    },
    gradeData: {
      type: Array
    },
    bonusData: {
      type: Array
    },
    levelData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        bonusMode: 0,
        isTighten: 1,
        //是否紧缩
        bonusTag: [],
        factorList: [],
        isAfterGrade: 0,
        jdType: 0,
        factorGrade: [],
        factorProduct: [],
        factorKv: {}
      },
      productData: []
    };
  },
  watch: {
    'form.factorList': {
      handler: function handler(val, oldVal) {
        for (var i in val) {
          var item = val[i];
          this.form.factorKv[item.grade + '' + item.product] = item.rate;
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;

    if (this.conditions) {
      for (var key in this.form) {
        if (this.conditions.hasOwnProperty(key)) {
          this.form[key] = this.conditions[key];
        }
      }
    }

    productList({
      page: 1,
      limit: 1000,
      where: {
        is_main: 1
      }
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.productData = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    getGradeName: function getGradeName(grade) {
      for (var i in this.gradeData) {
        if (this.gradeData[i].tag == grade) {
          return this.gradeData[i].name;
        }
      }

      return '';
    },
    getProductName: function getProductName(product) {
      for (var i in this.productData) {
        if (this.productData[i].id == product) {
          return this.productData[i].code + this.productData[i].store_name;
        }
      }

      return '';
    },
    createFactor: function createFactor() {
      var tmp = [];

      if (this.form.jdType === 1) {
        var _tmp = [];

        for (var i in this.form.factorGrade) {
          for (var j in this.form.factorProduct) {
            _tmp.push({
              grade: this.form.factorGrade[i],
              product: this.form.factorProduct[j],
              rate: 0
            });
          }
        }

        this.form.factorList = _tmp;
      } else {
        if (this.form.bonusMode === 0) {
          for (var _i in this.gradeData) {
            var item = this.gradeData[_i];
            tmp.push({
              grade: item.tag,
              gradeName: item.name,
              rateList: [{
                rate: 0
              }]
            });
          }
        } else {
          for (var _i2 in this.levelData) {
            var _item = this.levelData[_i2];
            tmp.push({
              grade: _item.tag,
              gradeName: _item.name,
              rateList: [{
                rate: 0
              }]
            });
          }
        }

        this.form.factorList = tmp;
      }
    },
    clearFactorData: function clearFactorData() {
      this.form.factorList = [];
      this.form.factorKv = {};
      this.form.factorProduct = [];
      this.form.factorGrade = [];
    },
    clickDelFactor: function clickDelFactor(index) {
      var tmp = this.form.factorList;
      tmp.splice(index, 1);
      this.form.factorList = tmp;
    },
    clickAddLayer: function clickAddLayer(index) {
      this.form.factorList[index].rateList.push({
        rate: 0
      });
    },
    clickDelLayer: function clickDelLayer(index, subIndex) {
      var tmp = this.form.factorList[index].rateList;
      tmp.splice(subIndex, 1);
      this.form.factorList.factorList[index] = tmp;
    },
    clickBatchSet: function clickBatchSet() {
      if (this.form.jdType === 1) {
        var tmp = this.form.factorList;
        var productCount = this.productData.length;
        var j = 0;
        var firstRate = 0;

        for (var i in tmp) {
          var item = tmp[i];

          if (j === 0) {
            firstRate = item.rate;
          } else {
            tmp[i].rate = firstRate;
          }

          j++;

          if (j === productCount) {
            j = 0;
          }
        }

        this.form.factorList = this.$tempModel.deepCopy(tmp);
      } else {
        if (this.form.factorList.length > 0) {
          var _item2 = this.form.factorList[0].rateList;
          var _tmp2 = this.form.factorList;

          for (var index in _tmp2) {
            _tmp2[index].rateList = _item2;
          }

          this.form.factorList = this.$tempModel.deepCopy(_tmp2);
        }
      }
    },
    getParams: function getParams() {
      var params = this.form;
      return this.$tempModel.deepCopy(params);
    }
  }
};