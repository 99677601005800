var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "店长审核",
        visible: _vm.dialogVisible,
        width: "500px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: { padding: "15px 30px", "background-color": "#f6f6f6" }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "审核意见" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("同意")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }