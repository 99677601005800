import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
export default {
  props: {
    config: {
      type: Object
    }
  },
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "店补等级名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "name"
        }, {
          "add": 1,
          "desc": "重1开始,按顺序填写，不允许重复",
          "detail": 1,
          "groupId": "",
          "label": "店补唯一标识",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "tag"
        }, {
          "label": "备注",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "300",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "remark"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      resolve({
        total: 0,
        data: this.config.storeData
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        var tmpData = this.$tempModel.deepCopy(this.config.storeData);

        for (var i in tmpData) {
          if (tmpData[i].id === row.id) {
            var tmp = this.$tempModel.deepCopy(params);
            tmp.id = row.id;
            tmpData[i] = tmp;
            this.config.storeData = tmpData;
            break;
          }
        }
      } else {
        params.id = this.config.storeId++;
        this.config.storeData.push(params);
      }

      resolve();
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      var tmp = this.$tempModel.deepCopy(this.config.storeData);
      tmp.map(function (item, idx) {
        if (item.id === ids[0]) {
          tmp.splice(idx, 1);
          return;
        }
      });
      this.config.storeData = tmp;
      resolve();
    },
    onClickLink: function onClickLink(prop, row) {//点击列
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};