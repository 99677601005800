import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'd2-header-size',
  data: function data() {
    return {
      options: [{
        label: '默认',
        value: 'default'
      }, {
        label: '中',
        value: 'medium'
      }, {
        label: '小',
        value: 'small'
      }, {
        label: '最小',
        value: 'mini'
      }]
    };
  },
  computed: _objectSpread({}, mapState('d2admin/size', ['value'])),
  methods: _objectSpread({}, mapMutations({
    pageKeepAliveClean: 'd2admin/page/keepAliveClean'
  }), {}, mapActions({
    sizeSet: 'd2admin/size/set'
  }), {
    handleChange: function handleChange(value) {
      this.sizeSet(value);
      this.$notify({
        title: '提示',
        dangerouslyUseHTMLString: true,
        message: '已更新页面内 <b>组件</b> 的 <b>默认尺寸</b><br/>例如按钮大小，<b>非字号</b>',
        type: 'success'
      });
    },
    iconName: function iconName(name) {
      return name === this.value ? 'dot-circle-o' : 'circle-o';
    }
  })
};