//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      row: null,
      isAdd: false,
      dialogVisible: false,
      factorList: [{
        name: '订单会员资格',
        tag: 'order_level',
        type: 'level'
      }, {
        name: '订单会员等级',
        tag: 'order_grade',
        type: 'level'
      }, {
        name: '订单业绩',
        tag: 'order_pv',
        type: 'pv'
      }, {
        name: '奖励会员资格',
        tag: 'give_level',
        type: 'level'
      }, {
        name: '奖励会员等级',
        tag: 'give_grade',
        type: 'level'
      }, {
        name: '奖励会员店补等级',
        tag: 'give_db_grade',
        type: 'level'
      }, {
        name: '奖励会员资格业绩',
        tag: 'give_level_pv',
        type: 'pv'
      }]
    };
  },
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;

      if (!row) {
        //添加状态
        this.row = {
          name: '',
          remark: '',
          config: {}
        };
        this.isAdd = true;
      } else {
        this.row = row;
        this.isAdd = false;
      }

      if (!this.row.config.hasOwnProperty('layer')) {
        this.row.config = {
          layer: 1,
          computeType: 0,
          //0非极差 1极差
          bonusMode: 0,
          //收益类型：0比率 1金额
          orderTypes: [],
          //生效的订单类型
          factor: [] //影响因素

        };
      }
    },
    clickSubmit: function clickSubmit() {}
  }
};