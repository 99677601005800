//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    centerBdOnlyChange: function centerBdOnlyChange(value) {
      console.log('centerBdOnlyChange', value);

      if (value == 0) {
        this.config.baseData.canCrossBd = 0;
      }
    }
  }
};