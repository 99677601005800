import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { storeApplyList } from '@/api/apis';
import storeApplyCheck from '@/views/pages/finance/storeApply/storeApplyCheck.vue';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    storeApplyCheck: storeApplyCheck
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "0"
            }],
            "title": "待审核",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "1"
            }],
            "title": "已同意",
            "type": "button"
          }, {
            "field": [{
              "key": "status",
              "value": "-1"
            }],
            "title": "已拒绝",
            "total": 0,
            "type": "button"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "审核状态",
          "type": 0,
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "default",
          "title": "审核",
          "type": "primary"
        }, {
          "icon": "",
          "id": "",
          "tag": "edit",
          "title": "修改证照信息",
          "type": "primary"
        }],
        "showPagination": true,
        "showSelection": false,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "60",
          "prop": "id"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "number"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "省",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "province"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "市",
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "city"
        }, {
          "add": 0,
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "区",
          "rowAddField": "",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "80",
          "prop": "district"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "申请时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "审核状态",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "审核时间",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "apply_time"
        }, {
          "add": 0,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "required": 0,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "remark"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司名称",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "user_company"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "营业执照编号",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "user_credit_code"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "公司类型",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "user_company_type"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "法人姓名",
          "required": 1,
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "user_legal_person"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "执照有效日期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "160",
          "prop": "user_company_valid_period"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "注册地址",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "200",
          "prop": "user_business_address"
        }, {
          "add": 0,
          "desc": "",
          "detail": 0,
          "excel": 0,
          "groupId": "",
          "label": "扫描件JSON",
          "rules": [],
          "show": 0,
          "type": "custom",
          "width": "400",
          "prop": "user_company_json"
        }, {
          "add": 1,
          "desc": "建议尺寸640x320 不超过256kb",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "执照附件",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "image",
          "width": "100",
          "prop": "ex5",
          "file": {
            "checkSize": 0,
            "exData": {
              "type": "default"
            },
            "fileType": ".png,.jpg,.jpeg",
            "height": 320,
            "id": 5,
            "key": "ex5",
            "maxNum": 1,
            "maxSize": 256,
            "width": 640
          }
        }, {
          "label": "身份证正面",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "image",
          "desc": "建议尺寸640x320 不超过256kb",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "required": 1,
          "prop": "ex1",
          "file": {
            "key": "ex1",
            "id": 6,
            "maxSize": 256,
            "maxNum": 1,
            "width": 640,
            "height": 320,
            "checkSize": 0,
            "fileType": ".png,.jpg,.jpeg",
            "exData": {
              "type": "default"
            }
          }
        }, {
          "label": "身份证反面",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "image",
          "desc": "建议尺寸640x320 不超过256kb",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "required": 1,
          "prop": "ex2",
          "file": {
            "key": "ex2",
            "id": 7,
            "maxSize": 256,
            "maxNum": 1,
            "width": 640,
            "height": 320,
            "checkSize": 0,
            "fileType": ".png,.jpg,.jpeg",
            "exData": {
              "type": "default"
            }
          }
        }, {
          "label": "身份证姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "excel": 1,
          "prop": "idcard_name"
        }, {
          "label": "身份证号码",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "excel": 1,
          "prop": "idcard_no"
        }, {
          "label": "身份证地址",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_address"
        }, {
          "label": "身份证性别",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_sex"
        }, {
          "label": "身份证生日",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_birth"
        }, {
          "label": "身份证籍贯",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_ethnicity"
        }, {
          "label": "分局名称",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_issue_authority"
        }, {
          "label": "有效期",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "idcard_valid_period"
        }],
        "tableName": "t_gaoda_apply",
        "tableRadioProps": {},
        "tableSelectProps": {
          "t_user.grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_t_usergrade"
          },
          "status": {
            "data": [{
              "key": 0,
              "value": "待审核"
            }, {
              "key": 1,
              "value": "已同意"
            }, {
              "key": -1,
              "value": "已拒绝"
            }],
            "id": 4,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "店长申请",
        "type": "tableTemplate"
      },
      remoteFunc: {
        tableFunc_select_t_usergrade: function tableFunc_select_t_usergrade(tag, relationData, resolve) {
          //筛选项模型数组
          //[{ key: "新品", value: "0" }]
          var data = [];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      storeApplyList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs.check.show(row, function () {
          _this2.table.reloadData();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      if (btnIndex === 0) {
        if (row.status !== 0) {
          return false;
        }
      } else if (btnIndex === 1) {
        if (row.status !== 1) {
          return false;
        }
      }

      return true;
    }
  }
};