var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "代理类型" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.agentType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "agentType", $$v)
                        },
                        expression: "form.agentType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("普通")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("产品")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生效的订单类型" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.orderTypes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "orderTypes", $$v)
                        },
                        expression: "form.orderTypes"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                        _vm._v("注册单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                        _vm._v("复销单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                        _vm._v("升级单")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.form.agentType !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "计算类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.bonusMode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "bonusMode", $$v)
                            },
                            expression: "form.bonusMode"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("比率")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("金额")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.bonusMode === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "计算方式" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.order,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "order", $$v)
                            },
                            expression: "form.order"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("按订单算")
                          ]),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("按产品数量算")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.agentType === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "参与的商品" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.form.factorProduct,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "factorProduct", $$v)
                            },
                            expression: "form.factorProduct"
                          }
                        },
                        _vm._l(_vm.productData, function(subItem) {
                          return _c(
                            "el-checkbox",
                            { key: subItem.id, attrs: { label: subItem.id } },
                            [
                              _vm._v(
                                _vm._s(subItem.code + subItem.store_name) + " "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.form.agentType !== 1
        ? _c(
            "el-card",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-table",
                { staticStyle: { width: "100%" }, attrs: { data: _vm.data } },
                [
                  _c("el-table-column", {
                    attrs: { label: "代理等级", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请选择代理等级"
                                  },
                                  model: {
                                    value: scope.row.grade,
                                    callback: function($$v) {
                                      _vm.$set(scope.row, "grade", $$v)
                                    },
                                    expression: "scope.row.grade"
                                  }
                                },
                                _vm._l(_vm.agentData, function(item) {
                                  return _c("el-option", {
                                    key: item.tag,
                                    attrs: { label: item.name, value: item.tag }
                                  })
                                }),
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2500059338
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "提成比例", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-row",
                                [
                                  _c("el-input-number", {
                                    staticStyle: { width: "80%" },
                                    attrs: { size: "mini", min: 0, max: 100 },
                                    model: {
                                      value: scope.row.rate,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "rate", $$v)
                                      },
                                      expression: "scope.row.rate"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.bonusMode === 0 ? "%" : "￥"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4240682138
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.clickDelete(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v("删除 ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1431131302
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.form.agentType === 1
        ? _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c("span", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("奖金奖励配置")
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.data, border: "", size: "mini" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "等级" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getGradeName(scope.row.grade))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      495970166
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "产品" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getProductName(scope.row.product))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1267083638
                    )
                  }),
                  _c("el-table-column", {
                    attrs: { label: "奖励金额" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input-number", {
                                attrs: { size: "mini", min: 0 },
                                model: {
                                  value: scope.row["rate"],
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "rate", $$v)
                                  },
                                  expression: "scope.row['rate']"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2274012965
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.clickAddAllCond }
            },
            [_vm._v("添加所有等级 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.resetAll }
            },
            [_vm._v("重置 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }