import "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/core-js/modules/es.promise.js";
import "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/core-js/modules/es.object.assign.js";
import "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/core-js/modules/es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App'; // 核心插件

import d2Admin from '@/plugin/d2admin'; // store

import store from '@/store/index'; // 菜单和路由设置

import router from './router';
import menuHeader from '@/menu/header';
import menuAside from '@/menu/aside';
import routes, { frameInRoutes } from '@/router/routes';
import VueUeditorWrap from 'vue-ueditor-wrap';
import { systemInfo } from '@/api/apis';
import setting from '@/setting'; // 核心插件

Vue.use(d2Admin);

if (process.env.VUE_APP_API === 'production') {
  console.log = function (oriLogFunc) {
    return function (str) {};
  }(console.log);
}

Vue.component('VueUeditorWrap', VueUeditorWrap);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {// // 设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // // 初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');