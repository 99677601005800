import "core-js/modules/es.array.join";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { memberBatchRecharge, memberMove, memberRepairMoney } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      accountList: [{
        key: '0',
        value: '无'
      }, {
        key: '1',
        value: '1级'
      }, {
        key: '2',
        value: '2级'
      }, {
        key: '3',
        value: '3级'
      }],
      form: {
        number: '',
        type: '0'
      },
      rules: {}
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(number, finish) {
      this.dialogVisible = true;
      this.form.number = number.join(',');
      this.finish = finish;
      this.numbers = number;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      this.$g.fun.startLoading();
      this.$refs['form'].validate(function (valid) {
        memberBatchRecharge({
          number: _this.numbers,
          is_recharge: _this.form.type
        }).then(function _callee(res) {
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _this.dialogVisible = false;

                  _this.$g.fun.endLoading();

                  _this.$alert('批量设置成功', '提示', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {
                      _this.finish();
                    }
                  });

                case 3:
                case "end":
                  return _context.stop();
              }
            }
          });
        });
      });
    }
  }
};