import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    conditions: {
      type: Object
    },
    gradeData: {
      type: Array
    },
    levelData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      data: [],
      form: {
        orderTypes: [],
        isWeight: 0,
        typeValue: 0,
        weightMode: 0
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;

    if (this.conditions) {
      this.data = this.conditions.data ? this.conditions.data : [];

      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }

      this.$nextTick(function () {
        _this.$refs.creator.setCondData(_this.$tempModel.deepCopy(_this.conditions.condData));
      });
    }
  },
  methods: {
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.data);
      tmp.splice(index, 1);
      this.data = tmp;
    },
    clickAddCond: function clickAddCond() {
      this.data.push({
        grade: '',
        rate: ''
      });
    },
    clickAddAllCond: function clickAddAllCond() {
      for (var i = 0; i < this.gradeData.length; i++) {
        var item = this.gradeData[i];
        this.data.push({
          grade: item.tag,
          rate: 0
        });
      }
    },
    getParams: function getParams() {
      var params = this.form;
      params.data = this.data;
      params.condData = this.$refs.creator.getCondData();
      console.log('getParams', params);
      return this.$tempModel.deepCopy(params);
    }
  }
};