import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.map";
import _toConsumableArray from "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
// 创建 el-menu-item
export function elMenuItem(createElement, menu) {
  return createElement('el-menu-item', {
    props: {
      index: menu.path
    }
  }, [].concat(_toConsumableArray(menu.icon ? [createElement('i', {
    attrs: {
      class: "fa fa-".concat(menu.icon)
    }
  })] : []), _toConsumableArray(menu.icon === undefined & !menu.iconSvg ? [createElement('i', {
    attrs: {
      class: 'fa fa-file-o'
    }
  })] : []), _toConsumableArray(menu.iconSvg ? [createElement('d2-icon-svg', {
    props: {
      name: menu.iconSvg
    }
  })] : []), [createElement('span', {
    slot: 'title'
  }, menu.title || '未命名菜单')]));
} // 创建 el-submenu

export function elSubmenu(createElement, menu) {
  var _this = this;

  return createElement('el-submenu', {
    props: {
      index: menu.path
    }
  }, [].concat(_toConsumableArray(menu.icon ? [createElement('i', {
    slot: 'title',
    attrs: {
      class: "fa fa-".concat(menu.icon)
    }
  })] : []), _toConsumableArray(menu.icon === undefined & !menu.iconSvg ? [createElement('i', {
    slot: 'title',
    attrs: {
      class: 'fa fa-folder-o'
    }
  })] : []), _toConsumableArray(menu.iconSvg ? [createElement('d2-icon-svg', {
    slot: 'title',
    props: {
      name: menu.iconSvg
    }
  })] : []), [createElement('span', {
    slot: 'title'
  }, menu.title || '未命名菜单')], _toConsumableArray(menu.children.map(function (child, childIndex) {
    return (child.children === undefined ? elMenuItem : elSubmenu).call(_this, createElement, child);
  }))));
}