//
//
//
//
//
//
//
import conditionGroupCell from './conditionGroupCell';
export default {
  components: {
    conditionGroupCell: conditionGroupCell
  },
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    }
  },
  data: function data() {
    return {
      relationCondition: {
        op: 'and',
        data: []
      },
      content: '',
      conditionList: [{
        id: 0,
        name: '团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '团队业绩',
          rename: '团队业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 1,
        name: '个人业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '个人业绩',
          rename: '个人业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, // {id: 18, name: '个人业绩除以系数', period: 0,type: [1,2,3],order:0, params: [{name: '整除系数',rename:'整除系数', value: 1, op: '=', unit: 'pv'},{name: '个人业绩/系数',rename:'个人业绩/系数', value: 0, op: '>=', unit: '单'}]},
      {
        id: 2,
        name: '大市场业绩',
        myself: 0,
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '大市场业绩',
          rename: '大市场业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 3,
        name: '小市场业绩',
        myself: 0,
        period: 0,
        type: [1, 2, 3],
        order: 0,
        params: [{
          name: '小市场业绩',
          rename: '小市场业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 4,
        name: '直推人数',
        params: [{
          name: '直推人数',
          rename: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 5,
        name: '团队人数',
        params: [{
          name: '团队人数',
          rename: '团队人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 6,
        name: '会员等级',
        params: [{
          name: '会员等级',
          rename: '会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '级别'
        }]
      }, {
        id: 7,
        name: '会员资格',
        params: [{
          name: '会员资格',
          rename: '会员资格',
          value: '',
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 8,
        name: '直推资格人数',
        params: [{
          name: '直推人数',
          rename: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }, {
          name: '直推会员资格',
          rename: '直推会员资格',
          value: '',
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 9,
        name: '直推等级人数',
        solid: 0,
        params: [{
          name: '直推人数',
          rename: '直推人数',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '直推会员等级',
          rename: '直推会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 12,
        name: '市场等级人数',
        params: [{
          name: '市场人数',
          rename: '市场人数',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '市场会员等级',
          rename: '市场会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 16,
        name: '市场资格人数',
        params: [{
          name: '市场人数',
          rename: '市场人数',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '市场会员等级',
          rename: '市场会员资格',
          value: '',
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 10,
        name: '一次购买商品数(秒结可用)',
        params: [{
          name: '商品数量',
          rename: '商品数量',
          value: 0,
          op: '>=',
          unit: '个'
        }]
      }, {
        id: 11,
        name: '只判断订单人(秒结可用)',
        params: []
      }, {
        id: 13,
        name: '直推等级团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          rename: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          rename: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          rename: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 14,
        name: '网下等级团队业绩',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          rename: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          rename: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          rename: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 15,
        name: '网下等级团队业绩(等级不同线)',
        rename: '',
        period: 0,
        type: [1, 2, 3],
        order: 0,
        myself: 0,
        params: [{
          name: '会员等级',
          rename: '会员等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '团队业绩',
          rename: '团队业绩',
          value: '',
          op: '>=',
          unit: 'pv'
        }, {
          name: '会员数量',
          rename: '会员数量',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 17,
        name: '团队等级人数(可同线/实单)',
        params: [{
          name: '团队人数',
          rename: '团队人数',
          value: 0,
          op: '>=',
          unit: '人'
        }, {
          name: '会员等级',
          rename: '会员等级',
          value: '',
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    isEmptyObject: function isEmptyObject(obj) {
      for (var n in obj) {
        return false;
      }

      return true;
    },
    getCondData: function getCondData() {
      console.log('getCondData', this.relationCondition);
      return this.relationCondition;
    },
    clickReset: function clickReset() {
      this.relationCondition = {
        op: 'and',
        data: []
      };
    },
    setCondData: function setCondData(value) {
      console.log('setCondData', value);

      if (!this.isEmptyObject(value)) {
        this.relationCondition = value;
      }
    }
  }
};