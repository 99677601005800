var baseUrl = '';

if (process.env.VUE_APP_API === 'dev') {
  // baseUrl = 'http://fenghuang-admin.saas.dd.sf-soft.com/api/'
  // baseUrl = 'http://shidanming-admin.saas.dd.sf-soft.com/api/'
  // baseUrl = 'http://qingshui-admin.saas.dd.sf-soft.com/api/'
  // baseUrl = 'http://gonghe-admin.saas.dd.sf-soft.com/api/'
  // baseUrl = 'http://xinsande-admin.dev.ddjsmt.com/api/'
  // baseUrl = 'http://jh-admin.saas.dd.sf-soft.com/api/'
  // baseUrl = 'http://knldb-admin.dev.ddjsmt.com/api/'
  // baseUrl = 'http://dingding-admin.dev.ddjsmt.com/api/'
  // baseUrl = 'http://jinmu-admin.dev.ddjsmt.com/api/'
  baseUrl = 'http://afsdb-admin.dev.ddjsmt.com/api/';
} else {
  baseUrl = '/api/';
}

export default {
  image: {
    loginLogo: require('./assets/img/login_logo.png'),
    //登录界面logo
    loginBg: require('./assets/img/login_bg.png'),
    //登录界面背景图片
    menuLogoExpand: require('./assets/img/menu_logo_expand.png'),
    //左侧菜单展开logo
    menuLogoUnExpand: require('./assets/img/menu_logo_unexpand.png') //左侧菜单折叠logo

  },
  systemId: -1,
  //当前查看的系统ID
  defaultSystemId: 1,
  //当前查看的系统ID
  resaleSystemId: 2,
  //复销系统id
  projectName: 'SAS后台管理系统',
  //项目名称
  //接口地址，支持多地址
  appApi: {
    base: baseUrl + 'v1/op/',
    baseClient: baseUrl + 'client/v1/',
    uploadFile: baseUrl + 'v1/op/file/upload',
    captcha: baseUrl + 'client/v1/auth/code',
    previewUrl: 'http://192.168.28.227:8080',
    timeout: 10000 //请求超时时间

  },
  version: '0.0.1',
  //版本号，跟cookie挂钩
  proId: 'sasAdmin',
  //项目唯一编码，跟cookie挂钩
  //登录配置
  loginConfig: {
    pwdError: {
      enable: true,
      //是否开启
      errorCount: 3,
      //错误几次后锁定，
      lockMinute: 5 //锁定多长时间

    },
    tokenExceedMinute: 700 //登录超时时间 单位分钟

  },
  //多级数据返回时的拼接字符串
  fieldSeparator: '-',
  //不需要权限访问的路由
  baseRoute: ['/login', '/log', '/refresh', '/redirect/:route*'],
  //开启网络请求参数和返回值输出
  openNetLog: false,
  //上传oss配置
  ossConfig: {
    platform: 2,
    //0阿里 1七牛 2自身服务器
    ossPath: '',
    ossDic: '/',
    //目录
    aliYunConfig: {
      region: '',
      accessKeyId: '',
      accessKeySecret: '',
      bucket: ''
    },
    qiNiuConfig: {
      accessKey: "",
      secretKey: "",
      bucket: "",
      port: "",
      upTokenUrl: "",
      // demo 启动后会在本地 /uptoken 上提供获取 uptoken 的接口，所以这里可以填 'token'
      domain: "" // Bucket 的外链默认域名，在 Bucket 的内容管理里查看，如：'http://xxx.bkt.clouddn.com/'

    }
  },
  // 支持快捷键 例如 ctrl+shift+s
  hotkey: {
    search: {
      open: 's',
      close: 'esc'
    }
  },
  // 侧边栏默认折叠状态
  menu: {
    asideCollapse: false
  },
  // 在读取持久化数据失败时默认页面
  page: {
    opened: [{
      name: 'index',
      fullPath: '/login',
      meta: {
        title: '登录',
        auth: true
      }
    }]
  },
  // 菜单搜索
  search: {
    enable: false
  },
  // 注册的主题
  theme: {
    defaultIndex: 4,
    //默认选中项
    force: false,
    list: [{
      title: 'Tomorrow Night Blue (vsCode)',
      name: 'tomorrow-night-blue',
      preview: 'image/theme/tomorrow-night-blue/preview@2x.png'
    }, {
      title: 'd2admin 经典',
      name: 'd2',
      preview: 'image/theme/d2/preview@2x.png'
    }, {
      title: '紫罗兰',
      name: 'violet',
      preview: 'image/theme/violet/preview@2x.png'
    }, {
      title: '简约线条',
      name: 'line',
      backgroundImage: 'image/theme/line/bg.jpg',
      preview: 'image/theme/line/preview@2x.png'
    }, {
      title: '流星',
      name: 'star',
      backgroundImage: 'image/theme/menu_bg.png',
      preview: 'image/theme/star/menu.jpg'
    }]
  },
  // 是否默认开启页面切换动画
  transition: {
    active: true
  }
};