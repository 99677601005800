import "core-js/modules/es.array.map";
import "core-js/modules/es.array.sort";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.string.search";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import tempForm from '@/components/rw/tempForm/index';
import tempChart from '@/components/rw/tempChart/index';
import gradeCond from './gradeCond';
import exaCond from './exaCond';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    gradeCond: gradeCond,
    exaCond: exaCond
  },
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    var _this = this;

    return {
      reloadTimer1: 'reloadTimer1',
      reloadTimer2: 'reloadTimer2',
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }, {
          "tag": "default",
          "type": null,
          "title": "按标识排序",
          "id": "",
          "icon": ""
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "定级条件",
          "type": "success"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "ID",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "是否启用",
          "rules": [],
          "show": 1,
          "type": "switch",
          "width": "80",
          "prop": "status"
        }, {
          "add": 1,
          "desc": "重0开始,按顺序填写，不允许重复",
          "detail": 1,
          "groupId": "",
          "label": "唯一标识",
          "required": 1,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "tag"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "等级名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "name"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "等级顺序",
          "required": 1,
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "sort"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "定级周期",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "period"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "备注",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "300",
          "prop": "remark"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "特殊计算标识",
          "detail": 1,
          "groupId": "",
          "label": "特殊标识",
          "required": 0,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "exa_action"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "period": {
            "data": [{
              "key": 0,
              "value": "日结"
            }, {
              "key": 1,
              "value": "周结"
            }, {
              "key": 2,
              "value": "月结"
            }, {
              "key": 3,
              "value": "秒结"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "isExamine": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {
          "status": {
            "activeValue": 1,
            "id": 4,
            "inactiveValue": 0,
            "integer": 1,
            "relation": false,
            "relationProps": []
          }
        },
        "title": "等级配置",
        "type": "tableTemplate"
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    findGradeNameById: function findGradeNameById(id) {
      for (var i in this.config.gradeData) {
        var item = this.config.gradeData[i];

        if (item.id == id) {
          return item.name;
        }
      }

      return '';
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      var tmp = this.config.gradeData;
      resolve({
        total: 0,
        data: tmp,
        isSplit: false
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        var tmpData = this.$tempModel.deepCopy(this.config.gradeData);

        for (var i in tmpData) {
          if (tmpData[i].id === row.id) {
            var tmp = this.$tempModel.deepCopy(row);

            for (var key in tmp) {
              if (params.hasOwnProperty(key)) {
                tmp[key] = params[key];
              }
            }

            tmpData[i] = tmp;
            this.config.gradeData = tmpData;
            break;
          }
        }
      } else {
        params.id = this.config.gradeId++;
        params.conditions = {
          condData: {}
        };
        params.isExamine = 0;
        params.exaAction = '';
        params.exaCond = 0;
        params.exaPeriod = 0;
        this.config.gradeData.push(params);
      }

      resolve();
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      var tmp = this.$tempModel.deepCopy(this.config.gradeData);
      tmp.map(function (item, idx) {
        if (item.id === ids[0]) {
          tmp.splice(idx, 1);
          return;
        }
      });
      this.config.gradeData = tmp;
      resolve();
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this2 = this;

      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs.cond.show(row, function () {
          _this2.table.reloadData();

          _this2.reloadTimer1 = new Date().getTime();
        });
      } // else if (btnIndex === 1) {
      //   this.$refs.exa.show(row, () => {
      //     this.table.reloadData()
      //     this.reloadTimer2 = new Date().getTime()
      //   })
      // }

    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      //点击列表头部按钮回调
      if (btnIndex === 1) {
        var tmp = this.config.gradeData.sort(function (a, b) {
          return a.tag - b.tag;
        });
        this.config.gradeData = tmp;
        this.table.reloadData();
      }
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};