var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            size: "small",
            model: _vm.config,
            "label-position": "left",
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "网站开关" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config["3"],
                    callback: function($$v) {
                      _vm.$set(_vm.config, "3", $$v)
                    },
                    expression: "config['3']"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "编号生成规则" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "formItemInput",
                  model: {
                    value: _vm.config.numberPrefix,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "numberPrefix", $$v)
                    },
                    expression: "config.numberPrefix"
                  }
                },
                [_c("template", { slot: "prepend" }, [_vm._v("前缀")])],
                2
              ),
              _c(
                "el-input",
                {
                  staticClass: "formItemInput",
                  model: {
                    value: _vm.config.numberNum,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "numberNum", $$v)
                    },
                    expression: "config.numberNum"
                  }
                },
                [_c("template", { slot: "prepend" }, [_vm._v("位数")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "默认密码设置" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.initPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "initPassword", $$v)
                  },
                  expression: "config.initPassword"
                }
              }),
              _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
                _vm._v("密码未填写状态下，一二级密码默认是会员编号")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付方式" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.payMode,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "payMode", $$v)
                    },
                    expression: "config.payMode"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: "balance" } }, [
                    _vm._v("余额支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "wechat" } }, [
                    _vm._v("微信支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "fuxiao" } }, [
                    _vm._v("积分支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "mix_fuxiao" } }, [
                    _vm._v("混合支付")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聚合快递接口key" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.juheExpress,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "juheExpress", $$v)
                  },
                  expression: "config.juheExpress"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("设置key后可查询快递信息")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聚合短信接口key" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.juheSMS,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "juheSMS", $$v)
                  },
                  expression: "config.juheSMS"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信快捷登录" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.smsLogin,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "smsLogin", $$v)
                    },
                    expression: "config.smsLogin"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品海报分享" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.posterShare,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "posterShare", $$v)
                    },
                    expression: "config.posterShare"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮费" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.expressFee,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "expressFee", $$v)
                  },
                  expression: "config.expressFee"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("全国通用邮费")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "满免邮费" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.orderFreeExpress,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "orderFreeExpress", $$v)
                  },
                  expression: "config.orderFreeExpress"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("满多少免邮费")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "后台二次验证码" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.secondVerify,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "secondVerify", $$v)
                    },
                    expression: "config.secondVerify"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服电话" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.serviceTel,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "serviceTel", $$v)
                  },
                  expression: "config.serviceTel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "网站标题" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.webTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "webTitle", $$v)
                  },
                  expression: "config.webTitle"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { float: "right", "margin-top": "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }