import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import localeMixin from '@/locales/mixin.js';
import md5 from 'js-md5';
import setting from '@/setting';
import user from '@/store/modules/d2admin/modules/user';
import util from '../../../libs/util';
import { accountCodeBind } from '../../../api/apis';
import googleCode from './googleCode';
import aes from './aes';
export default {
  components: {
    googleCode: googleCode
  },
  mixins: [localeMixin],
  data: function data() {
    return {
      imgLogo: setting.image.loginLogo,
      imgBg: setting.image.loginBg,
      title: setting.projectName,
      timeInterval: null,
      time: dayjs().format('HH:mm:ss'),
      // 快速选择用户
      dialogVisible: false,
      validCode: '',
      users: [{
        name: 'Admin',
        username: 'admin',
        password: 'admin'
      }],
      // 表单
      formLogin: {
        username: '',
        password: '',
        code: ''
      },
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  methods: _objectSpread({}, mapActions('d2admin/account', ['login']), {
    refreshTime: function refreshTime() {
      this.time = dayjs().format('HH:mm:ss');
    },

    /**
     * @description 接收选择一个用户快速登录的事件
     * @param {Object} user 用户信息
     */
    handleUserBtnClick: function handleUserBtnClick(user) {
      this.formLogin.username = user.username;
      this.formLogin.password = user.password;
      this.submit();
    },

    /**
     * @description 提交表单
     */
    // 提交登录信息
    submit: function submit() {
      var _this2 = this;

      var _this = this; // if (_this.validCode.toUpperCase() !== _this.formLogin.code.toUpperCase()) {
      //   console.log(_this.validCode)
      //   return _this.$message.error('验证码错误')
      // }


      var account = this.formLogin.username;
      var pwd = aes.encrypt(this.formLogin.password);
      console.log('pwd', pwd);

      if (setting.loginConfig.pwdError.enable) {
        var errCount = util.cookies.get('errCount_' + account);

        if (errCount >= setting.loginConfig.pwdError.errorCount) {
          this.$message.error('您已连续输入' + errCount + '次错误密码，' + setting.loginConfig.pwdError.lockMinute + '分钟后可再次登录');
          return;
        }
      }

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 登录
          // 注意 这里的演示没有传验证码
          // 具体需要传递的数据请自行修改代码
          _this2.login({
            account: account,
            pwd: pwd,
            code: _this2.formLogin.code
          }).then(function () {
            _this2.$tempModel.powerOf = function (tid) {
              if (!tid || tid === '') {
                return true;
              }

              var userRules = user.state.info.rules_ids;

              if (userRules.indexOf(parseInt(tid)) >= 0) {
                return true;
              }

              return false;
            };

            util.cookies.set('errCount_' + account, 0); // 重定向对象不存在则返回顶层路径

            _this2.$router.replace('/index');
          }).catch(function (err) {
            if (err.message === '密码错误') {
              if (setting.loginConfig.pwdError.enable) {
                var _errCount = util.cookies.get('errCount_' + account);

                if (!_errCount) {
                  _errCount = 0;
                }

                _errCount = parseInt(_errCount + '') + 1;
                util.cookies.set('errCount_' + account, _errCount + '', {
                  expires: 1 / (24 * 60) * setting.loginConfig.pwdError.lockMinute
                });

                _this2.$message.error('连续输入密码错误' + _errCount + '次，连续错误' + setting.loginConfig.pwdError.errorCount + '次将冻结' + setting.loginConfig.pwdError.lockMinute + '分钟');
              }
            }
          });
        } else {
          // 登录表单校验失败
          _this2.$message.error('表单校验失败，请检查');
        }
      });
    },
    clickBindCode: function clickBindCode() {
      this.$refs.googleCode.show();
    }
  })
};