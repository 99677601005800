var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.config } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "给予复销扣费积分" } },
            [
              _c(
                "el-row",
                [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 0, max: 10 },
                    model: {
                      value: _vm.config.baseData.resaleMultiple1,
                      callback: function($$v) {
                        _vm.$set(_vm.config.baseData, "resaleMultiple1", $$v)
                      },
                      expression: "config.baseData.resaleMultiple1"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("倍")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "过期清零天数" } },
            [
              _c(
                "el-row",
                [
                  _c("el-input-number", {
                    attrs: { size: "mini", min: 0, max: 3650 },
                    model: {
                      value: _vm.config.baseData.resaleTax1ValidityDay,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.config.baseData,
                          "resaleTax1ValidityDay",
                          $$v
                        )
                      },
                      expression: "config.baseData.resaleTax1ValidityDay"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("天")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.config.baseData.resaleTax1 }
        },
        [
          _c("el-table-column", {
            attrs: { label: "会员等级", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          size: "mini",
                          multiple: true,
                          placeholder: "请选择可获得的等级"
                        },
                        model: {
                          value: scope.row.levels,
                          callback: function($$v) {
                            _vm.$set(scope.row, "levels", $$v)
                          },
                          expression: "scope.row.levels"
                        }
                      },
                      _vm._l(_vm.config.levelData, function(item) {
                        return _c("el-option", {
                          key: item.tag,
                          attrs: { label: item.name, value: item.tag }
                        })
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "奖金项", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: { size: "mini", placeholder: "请选择将奖金项" },
                        model: {
                          value: scope.row.bonus,
                          callback: function($$v) {
                            _vm.$set(scope.row, "bonus", $$v)
                          },
                          expression: "scope.row.bonus"
                        }
                      },
                      _vm._l(_vm.config.bonusData, function(item) {
                        return _c("el-option", {
                          key: item.tag,
                          attrs: { label: item.name, value: item.tag }
                        })
                      }),
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "扣除比例", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c("el-input-number", {
                          staticStyle: { width: "80%" },
                          attrs: { size: "mini", min: 0, max: 100 },
                          model: {
                            value: scope.row.rate,
                            callback: function($$v) {
                              _vm.$set(scope.row, "rate", $$v)
                            },
                            expression: "scope.row.rate"
                          }
                        }),
                        _c("span", [_vm._v("%")])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.clickDelete(scope.$index, scope.row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.clickAddCond }
            },
            [_vm._v("添加 ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.clickAddAllCond }
            },
            [_vm._v("添加所有奖金 ")]
          ),
          _c(
            "el-input",
            {
              staticStyle: { width: "200px", "margin-left": "10px" },
              attrs: { size: "small", placeholder: "" },
              model: {
                value: _vm.mulRate,
                callback: function($$v) {
                  _vm.mulRate = $$v
                },
                expression: "mulRate"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "append" },
                  on: { click: _vm.clickMulRate },
                  slot: "append"
                },
                [_vm._v("批量设置比率")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }