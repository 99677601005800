import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configBonusList, pondSourceList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function (_data) {
    function data() {
      return _data.apply(this, arguments);
    }

    data.toString = function () {
      return _data.toString();
    };

    return data;
  }(function () {
    var _this = this;

    return {
      totalPonds: 0,
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "类型",
          "select": 0,
          "type": 0,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "体外奖金",
            "field": [{
              "key": "type",
              "value": "0"
            }]
          }, {
            "type": "button",
            "title": "沉淀奖金",
            "field": [{
              "key": "type",
              "value": "1"
            }]
          }],
          "remoteFunc": []
        }, {
          "title": "奖金类型",
          "select": 0,
          "type": 3,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "type",
              "value": "0",
              "desc": "类型一"
            }, {
              "key": "type",
              "value": "1",
              "desc": "类型二"
            }]
          }],
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "title": "添加日期",
          "select": 0,
          "type": 1,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "title": "按条件",
          "select": 0,
          "type": 2,
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "number",
              "value": "",
              "desc": "会员编号"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "showSummary": true,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "期数",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 80,
          "prop": "expect_num"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "金额",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "bonus"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "比率",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "bonus_rate"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "奖金类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "bonus_tag"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "类型",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "type"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "add_time"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "order_id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "订单PV",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "order_pv"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "备注",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "70",
          "prop": "remark"
        }],
        "tableName": "t_balance",
        "tableRadioProps": {},
        "tableSelectProps": {
          "type": {
            "data": [{
              "key": 0,
              "value": "体外奖金"
            }, {
              "key": 1,
              "value": "沉淀奖金"
            }],
            "id": 5,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "bonus_tag": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 6,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "remoteFunc": "tableFunc_select_bonus_tag"
          }
        },
        "tableSwitchProps": {},
        "title": "奖金",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          configBonusList({}).then(function _callee(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'bonus_tag',
                        value: item.tag,
                        desc: item.name
                      });
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        },
        tableFunc_select_bonus_tag: function tableFunc_select_bonus_tag(tag, relationData, resolve) {
          //筛选项模型数组
          configBonusList({}).then(function _callee2(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
          resolve(tag, data);
        }
      }
    };
  }),
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      pondSourceList(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this2.totalPonds = res.bonus_sum;
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};