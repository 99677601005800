var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            size: "small",
            model: _vm.config,
            "label-position": "left",
            "label-width": "180px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "网站开关" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config["3"],
                    callback: function($$v) {
                      _vm.$set(_vm.config, "3", $$v)
                    },
                    expression: "config['3']"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否开放注册" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isRegOpen,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isRegOpen", $$v)
                    },
                    expression: "config.isRegOpen"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("关闭(不允许新用户注册)")
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "编号生成规则" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "formItemInput",
                  model: {
                    value: _vm.config.numberPrefix,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "numberPrefix", $$v)
                    },
                    expression: "config.numberPrefix"
                  }
                },
                [_c("template", { slot: "prepend" }, [_vm._v("前缀")])],
                2
              ),
              _c(
                "el-input",
                {
                  staticClass: "formItemInput",
                  model: {
                    value: _vm.config.numberNum,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "numberNum", $$v)
                    },
                    expression: "config.numberNum"
                  }
                },
                [_c("template", { slot: "prepend" }, [_vm._v("位数")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "默认密码设置" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.initPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "initPassword", $$v)
                  },
                  expression: "config.initPassword"
                }
              }),
              _c("el-tag", { attrs: { type: "danger", size: "mini" } }, [
                _vm._v("密码未填写状态下，一二级密码默认是会员编号")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付方式" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.payMode,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "payMode", $$v)
                    },
                    expression: "config.payMode"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: "balance" } }, [
                    _vm._v("余额支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "wechat" } }, [
                    _vm._v("微信支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "fuxiao" } }, [
                    _vm._v("积分支付")
                  ]),
                  _c("el-checkbox", { attrs: { label: "fuxiao2" } }, [
                    _vm._v("复消积分二")
                  ]),
                  _c("el-checkbox", { attrs: { label: "mix_fuxiao" } }, [
                    _vm._v("混合支付")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聚合快递接口key" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.juheExpress,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "juheExpress", $$v)
                  },
                  expression: "config.juheExpress"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("设置key后可查询快递信息")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "聚合短信接口key" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.juheSMS,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "juheSMS", $$v)
                  },
                  expression: "config.juheSMS"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信快捷登录" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.smsLogin,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "smsLogin", $$v)
                    },
                    expression: "config.smsLogin"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品海报分享" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.posterShare,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "posterShare", $$v)
                    },
                    expression: "config.posterShare"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "邮费" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.expressFee,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "expressFee", $$v)
                  },
                  expression: "config.expressFee"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("全国通用邮费")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "满免邮费" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.orderFreeExpress,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "orderFreeExpress", $$v)
                  },
                  expression: "config.orderFreeExpress"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("满多少免邮费")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "后台二次验证码" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.secondVerify,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "secondVerify", $$v)
                    },
                    expression: "config.secondVerify"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服电话" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.serviceTel,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "serviceTel", $$v)
                  },
                  expression: "config.serviceTel"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "网站标题" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.webTitle,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "webTitle", $$v)
                  },
                  expression: "config.webTitle"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "用户是否可以修改资料" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isEditProfile,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isEditProfile", $$v)
                    },
                    expression: "config.isEditProfile"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "会员是否可以撤销订单" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isMemberCancelOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isMemberCancelOrder", $$v)
                    },
                    expression: "config.isMemberCancelOrder"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "报单中心是否可以复销单" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isRechargeBuy2,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isRechargeBuy2", $$v)
                    },
                    expression: "config.isRechargeBuy2"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册是否需要证件号" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isRegIdCard,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isRegIdCard", $$v)
                    },
                    expression: "config.isRegIdCard"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册是否需要短信验证" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.isRegSMS,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "isRegSMS", $$v)
                    },
                    expression: "config.isRegSMS"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "撤单的时候删除用户" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.deleteUserByCancelOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "deleteUserByCancelOrder", $$v)
                    },
                    expression: "config.deleteUserByCancelOrder"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否允许手机号业务操作" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.usePhoneBusiness,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "usePhoneBusiness", $$v)
                    },
                    expression: "config.usePhoneBusiness"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "复销邮费" } },
            [
              _c(
                "el-input",
                {
                  staticClass: "formItemInput",
                  model: {
                    value: _vm.config.fxExpressFee,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "fxExpressFee", $$v)
                    },
                    expression: "config.fxExpressFee"
                  }
                },
                [
                  _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                    _vm._v("元")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "满免邮费" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.fxFreeExpress,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "fxFreeExpress", $$v)
                  },
                  expression: "config.fxFreeExpress"
                }
              }),
              _c("el-tag", { attrs: { type: "info", size: "mini" } }, [
                _vm._v("满多少免邮费")
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启复销商城" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.openFuXiaoShop,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "openFuXiaoShop", $$v)
                    },
                    expression: "config.openFuXiaoShop"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示复销账户" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.showFuXiaoMoney,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "showFuXiaoMoney", $$v)
                    },
                    expression: "config.showFuXiaoMoney"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否显示充值中心账户" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.showFuXiaoMoney,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "showFuXiaoMoney", $$v)
                    },
                    expression: "config.showFuXiaoMoney"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证限制个数" } },
            [
              _c("el-input-number", {
                attrs: { min: 1 },
                model: {
                  value: _vm.config.idCardNum,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "idCardNum", $$v)
                  },
                  expression: "config.idCardNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "我的市场显示类型" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.marketShowType,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "marketShowType", $$v)
                    },
                    expression: "config.marketShowType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("显示商品数量")
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("显示订单数量")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "系统主题颜色" } }, [
            _c(
              "div",
              { staticClass: "colorList" },
              _vm._l(_vm.colors, function(item) {
                return _c(
                  "div",
                  {
                    class: [
                      _vm.colorItem,
                      _vm.config.themeColor === item.color
                        ? _vm.colorItemSelect
                        : null
                    ],
                    on: {
                      click: function($event) {
                        return _vm.clickSelectThemeColor(item.color)
                      }
                    }
                  },
                  [
                    _c("div", {
                      staticClass: "colorBox",
                      style: { backgroundColor: item.color }
                    }),
                    _c("div", [_vm._v(_vm._s(item.title))])
                  ]
                )
              }),
              0
            )
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "转账关系开关" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.accountTransfer,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "accountTransfer", $$v)
                    },
                    expression: "config.accountTransfer"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1 } }, [
                    _vm._v("消费账户互转")
                  ]),
                  _c("el-checkbox", { attrs: { label: 2 } }, [
                    _vm._v("收益转消费")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3 } }, [
                    _vm._v("充值中心转消费")
                  ]),
                  _c("el-checkbox", { attrs: { label: 4 } }, [
                    _vm._v("复消转复消")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "复销限购时间" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.config.fxLimitDate,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "fxLimitDate", $$v)
                  },
                  expression: "config.fxLimitDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "复销限购金额" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.fxLimitMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "fxLimitMoney", $$v)
                  },
                  expression: "config.fxLimitMoney"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商城限购天数（注册之日起多少天内）" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.orderLimitDate,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "orderLimitDate", $$v)
                  },
                  expression: "config.orderLimitDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商城限购金额" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.orderLimitMoney,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "orderLimitMoney", $$v)
                  },
                  expression: "config.orderLimitMoney"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册最大年龄" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.regAgeLimit,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "regAgeLimit", $$v)
                  },
                  expression: "config.regAgeLimit"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可查询交易流水的天数" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.logDays,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "logDays", $$v)
                  },
                  expression: "config.logDays"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "（我的市场）市场人数是否包含自己" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.marketNumType,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "marketNumType", $$v)
                    },
                    expression: "config.marketNumType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "市场业绩系数" } },
            [
              _c("el-input", {
                staticClass: "formItemInput",
                model: {
                  value: _vm.config.povDivNum,
                  callback: function($$v) {
                    _vm.$set(_vm.config, "povDivNum", $$v)
                  },
                  expression: "config.povDivNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否允许虚拟币转账" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.allowTransfer,
                    callback: function($$v) {
                      _vm.$set(_vm.config, "allowTransfer", $$v)
                    },
                    expression: "config.allowTransfer"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.exArray, function(index, key) {
            return _vm.isShowEx(key)
              ? _c(
                  "el-form-item",
                  { attrs: { label: _vm.exArray[key] } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.config[key],
                          callback: function($$v) {
                            _vm.$set(_vm.config, key, $$v)
                          },
                          expression: "config[key]"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                        _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          }),
          _vm._l(_vm.exInputArray, function(index, key) {
            return _vm.isShowExInput(key)
              ? _c(
                  "el-form-item",
                  { attrs: { label: _vm.exInputArray[key] } },
                  [
                    _c("el-input", {
                      staticClass: "formItemInput",
                      model: {
                        value: _vm.config[key],
                        callback: function($$v) {
                          _vm.$set(_vm.config, key, $$v)
                        },
                        expression: "config[key]"
                      }
                    }),
                    _c("el-tag", [_vm._v(_vm._s(_vm.configDes[key]))])
                  ],
                  1
                )
              : _vm._e()
          })
        ],
        2
      ),
      _c(
        "div",
        { staticStyle: { float: "right", "margin-top": "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }