import { render, staticRenderFns } from "./index.vue?vue&type=template&id=065ea82d&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=065ea82d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065ea82d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/suifeng/Desktop/projects/2022/sdSas/sdSasAdmin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('065ea82d')) {
      api.createRecord('065ea82d', component.options)
    } else {
      api.reload('065ea82d', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=065ea82d&scoped=true&", function () {
      api.rerender('065ea82d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/sysconfig/systemCreator/systemConfig/baseConfig/index.vue"
export default component.exports