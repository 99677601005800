import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.split";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { staticsOrderList } from '../../../../api/apis';
export default {
  name: 'reportorder',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      numbers: '',
      add_time: [],
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }, {
          "icon": "",
          "id": "",
          "tag": "import",
          "title": "导入会员",
          "type": "success"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "会员编号",
          "type": 2,
          "remoteFunc": []
        }, {
          "title": "订单日期",
          "select": 0,
          "type": 1,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "button",
            "title": "全部",
            "field": []
          }, {
            "type": "button",
            "title": "今天",
            "field": []
          }, {
            "type": "button",
            "title": "昨天",
            "field": []
          }, {
            "type": "button",
            "title": "最近7天",
            "field": []
          }, {
            "type": "button",
            "title": "本月",
            "field": []
          }, {
            "type": "button",
            "title": "本年",
            "field": []
          }, {
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_2"]
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "border": true,
        "del": false,
        "headerButton": [],
        "id": "id_1001",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "detail",
          "title": "详情",
          "type": null
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "number"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员姓名",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "real_name"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "推荐网报单金额",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "110",
          "prop": "net_sum_price"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "推荐网报单业绩",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "110",
          "prop": "net_sum_pv"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "568单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "main_568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "990单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "main_990"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "2568单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 105,
          "prop": "main_2568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "8568单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "main_8568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "追购300单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "main_300"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "568+300单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "has_300_568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "990+300单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "has_300_990"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "2568+300单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "has_300_2568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "8568+300单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "has_300_8568"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "复销单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "fx"
        }, {
          "label": "其他",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "80",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "other"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "总单数",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "total_count"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {},
        "tableSwitchProps": {},
        "title": "单数统计",
        "type": "tableTemplate"
      },
      remoteFunc: {
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "add_time",
            value: []
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "add_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "add_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      params.number = this.numbers.split(',');
      params.add_time = this.add_time;
      staticsOrderList(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: 0,
                  data: res
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onSearch: function onSearch() {
      this.table.reloadData();
    },
    onExport: function onExport() {
      this.table.exportExcel();
    },
    onImport: function onImport() {
      this.$refs.import.value = null; //解决change事件在同一个文件下不触发的问题

      this.$refs.import.click();
    },
    onExcelImport: function onExcelImport(event) {
      var _this2 = this;

      var file = event.target.files[0];
      this.$import.xlsx(file).then(function (_ref) {
        var header = _ref.header,
            results = _ref.results;
        var data = [];

        try {
          for (var i in results) {
            var item = results[i];
            data.push(item['会员编号']);
          }

          _this2.numbers = data.join(',');

          _this2.table.reloadData();
        } catch (err) {
          _this2.$message({
            message: '表格格式错误',
            type: 'error'
          });
        }
      });
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};