import "core-js/modules/es.array.sort";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { configGradeList, systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
import tempAdd from '@/components/rw/tempAdd/index';
import Sortable from "sortablejs";
import phoneView from './phoneView';
import setting from '../../../../setting';
export default {
  name: 'personalConfig',
  components: {
    phoneView: phoneView,
    tempAdd: tempAdd
  },
  data: function data() {
    var _this = this;

    return {
      styleTag: 0,
      phoneUrl: setting.appApi.previewUrl + '/pages/profile/previewProfile',
      gradeList: [],
      tableData: [],
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "id": "",
          "tag": "detail",
          "title": "详情"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "desc": "唯一，不可重复",
          "detail": 1,
          "groupId": "",
          "label": "KEY",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "key"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "功能",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "name"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "是否可见",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "show"
        }, {
          "add": 1,
          "defaultValue": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "验证二级密码",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "pw2"
        }, {
          "add": 1,
          "defaultValue": null,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "可见等级",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": null,
          "prop": "grade"
        }, {
          "add": 1,
          "desc": "数值小的在前面",
          "detail": 1,
          "groupId": "",
          "label": "排序",
          "rules": [{
            "name": "integer"
          }],
          "show": 1,
          "type": "text",
          "width": null,
          "prop": "sort"
        }, {
          "label": "图标",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "icon"
        }, {
          "label": "路由",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "url"
        }, {
          "label": "图标颜色",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "color",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "color"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "show": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 2,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "pw2": {
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "id": 3,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "grade": {
            "data": [{
              "key": null,
              "value": ""
            }],
            "id": 4,
            "multiple": 1,
            "relation": false,
            "relationProps": [],
            "remote": true,
            "width": "100%",
            "remoteFunc": "tableFunc_select_grade"
          }
        },
        "tableSwitchProps": {},
        "title": "",
        "type": "tableTemplate"
      },
      remoteFunc: {
        tableFunc_select_grade: function tableFunc_select_grade(tag, relationData, resolve) {
          //筛选项模型数组
          resolve(tag, _this.gradeList);
        }
      }
    };
  },
  watch: {
    tableData: {
      deep: true,
      handler: function handler(val) {
        this.reloadData();
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    var params = {
      filed: ['personCenter']
    };
    this.initTableSort();
    systemWebConfig(params).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.tableData = res[0].value.menuData;
              _this2.styleTag = res[0].value.styleTag;
              setTimeout(function () {
                _this2.reloadData();
              }, 2000);

            case 3:
            case "end":
              return _context.stop();
          }
        }
      });
    });
    this.getGradeList();
  },
  methods: {
    reloadData: function reloadData() {
      this.$refs.phone.reloadPhone({
        styleTag: this.styleTag,
        data: this.tableData
      });
    },
    initTableSort: function initTableSort() {
      var tbody = document.querySelector('.tableDataPer .el-table__body-wrapper tbody');

      var _this = this;

      new Sortable(tbody, {
        onEnd: function onEnd(_ref) {
          var newIndex = _ref.newIndex,
              oldIndex = _ref.oldIndex;

          var currRow = _this.tableData.splice(oldIndex, 1)[0];

          _this.tableData.splice(newIndex, 0, currRow);

          var tmp = _this.tableData;

          for (var i in tmp) {
            tmp[i].sort = parseInt(i);
          }

          _this.tableData = tmp;
        }
      });
    },
    updateTableData: function updateTableData(data) {
      data.sort(function (a, b) {
        return b.sort === a.sort ? 0 : a.sort > b.sort ? 1 : -1;
      });
      this.tableData = data;
    },
    getGradeList: function getGradeList() {
      var _this3 = this;

      configGradeList({}).then(function _callee2(res) {
        var data, i;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                data = [{
                  key: -1,
                  value: '不限'
                }];

                for (i in res.rows) {
                  data.push({
                    key: res.rows[i].tag,
                    value: res.rows[i].name
                  });
                }

                _this3.gradeList = data;

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    onReqAdd: function onReqAdd(isEdit, params, row, resolveAdd) {
      if (isEdit) {
        var tmp = this.$tempModel.deepCopy(this.tableData);

        for (var i in tmp) {
          if (tmp[i].key === params.key) {
            tmp[i] = params;
            break;
          }
        }

        this.updateTableData(tmp);
      } else {
        var _tmp = this.$tempModel.deepCopy(this.tableData);

        for (var _i in this.tableData) {
          if (_tmp.key === params.key) {
            this.$message.error('KEY已存在');
            resolveAdd(true);
            return;
          }
        }

        _tmp.push(params);

        this.updateTableData(_tmp);
      }

      resolveAdd();
    },
    clickAdd: function clickAdd() {
      this.$refs['add'].show(false, null);
    },
    clickEdit: function clickEdit(row) {
      this.$refs['add'].show(true, row);
    },
    clickDel: function clickDel(row) {
      var tmp = this.$tempModel.deepCopy(this.tableData);

      for (var i in tmp) {
        if (tmp[i].key === row.key) {
          tmp.splice(parseInt(i), 1);
          this.updateTableData(tmp);
          break;
        }
      }
    },
    clickSubmit: function clickSubmit() {
      var _this4 = this;

      var params = [{
        id: 'personCenter',
        value: JSON.stringify({
          styleTag: this.styleTag,
          menuData: this.tableData
        })
      }];
      systemWebConfigAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _this4.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    }
  }
};