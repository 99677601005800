import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { productList } from '@/api/apis';
import form from 'element-ui/packages/form';
export default {
  props: {
    conditions: {
      type: Object
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      data: [],
      agentData: [{
        tag: 1,
        name: '区代'
      }, {
        tag: 2,
        name: '市代'
      }, {
        tag: 3,
        name: '省代'
      }],
      form: {
        agentType: 0,
        orderTypes: [1, 2, 3],
        order: 0,
        bonus: 0,
        bonusMode: 0,
        factorProduct: [],
        factorKv: {}
      },
      productData: []
    };
  },
  watch: {
    data: {
      handler: function handler(val, oldVal) {
        for (var i in val) {
          var item = val[i];
          this.form.factorKv[item.grade + '' + item.product] = item.rate;
        }
      },
      deep: true
    }
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;

    if (this.conditions) {
      this.data = this.conditions.data;

      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }
    }

    productList({
      page: 1,
      limit: 1000,
      where: {
        is_main: 1
      }
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.productData = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    getGradeName: function getGradeName(grade) {
      for (var i in this.agentData) {
        if (this.agentData[i].tag == grade) {
          return this.agentData[i].name;
        }
      }

      return '';
    },
    getProductName: function getProductName(product) {
      for (var i in this.productData) {
        if (this.productData[i].id == product) {
          return this.productData[i].code + this.productData[i].store_name;
        }
      }

      return '';
    },
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.data);
      tmp.splice(index, 1);
      this.data = tmp;
    },
    clickAddAllCond: function clickAddAllCond() {
      if (this.form.agentType === 1) {
        for (var i = 0; i < this.agentData.length; i++) {
          for (var j in this.form.factorProduct) {
            this.data.push({
              grade: this.agentData[i].tag,
              product: this.form.factorProduct[j],
              rate: 0
            });
          }
        }

        console.log('this.data', this.data);
      } else {
        for (var _i = 0; _i < this.agentData.length; _i++) {
          var item = this.agentData[_i];
          this.data.push({
            grade: item.tag,
            rate: 0
          });
        }
      }
    },
    resetAll: function resetAll() {
      this.data = [];
      this.form.factorKv = {};
      this.form.factorProduct = [];
    },
    getParams: function getParams() {
      var params = this.form;
      params.data = this.data;
      return this.$tempModel.deepCopy(params);
    }
  }
};