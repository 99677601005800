import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { storeApplyHandle } from '@/api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      statusList: [{
        key: 1,
        value: '同意'
      }, {
        key: -1,
        value: '拒绝'
      }],
      form: {
        status: 1,
        remark: ''
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.id = row.id;
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      // if (this.form.bankId === ''){
      //   this.$message({message:'请选择银行卡号',type:'error'})
      //   return
      // }
      this.$g.fun.startLoading('');
      var params = {
        id: this.id,
        status: this.form.status,
        remark: this.form.remark
      };
      storeApplyHandle(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.$message({
                  type: 'success',
                  message: '操作成功'
                });

                _this.$g.fun.endLoading();

                _this.dialogVisible = false;

                _this.finish();

              case 4:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    }
  }
};