import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import areaHandle from './areaHandle';
import { areaAdd, areaList, areaListByPid, areaDel, agentList } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    areaHandle: areaHandle
  },
  data: function data() {
    var _this = this;

    return {
      region: [],
      options: [],
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "default",
          "title": "添加/编辑代理",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [],
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "ID",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "会员编号",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "90",
          "prop": "number"
        }, {
          "label": "会员姓名",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "100",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "t_user.real_name"
        }, {
          "label": "会员电话",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "120",
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "excel": 1,
          "prop": "t_user.phone"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "代理级别",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "level"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "省",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "province"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "市",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "city"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "区",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "100",
          "prop": "district"
        }, {
          "add": 1,
          "detail": 1,
          "excel": 1,
          "groupId": "",
          "label": "添加日期",
          "rowAddField": "",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": 160,
          "prop": "add_time"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "代理产品",
          "rules": [],
          "show": 1,
          "type": "custom",
          "width": "300",
          "excel": 1,
          "prop": "product"
        }],
        "tableName": "t_agent",
        "tableRadioProps": {},
        "tableSelectProps": {
          "level": {
            "data": [{
              "key": 1,
              "value": "区代"
            }, {
              "key": 2,
              "value": "市代"
            }, {
              "key": 3,
              "value": "省代"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          }
        },
        "tableSwitchProps": {},
        "title": "区",
        "type": "tableTemplate"
      },
      searchModel_1001: {
        "del": false,
        "id": "id_1001",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }, {
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "t_user.real_name",
              "desc": "会员姓名"
            }],
            "operator": []
          }, {
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "t_user.phone",
              "desc": "会员电话"
            }],
            "operator": []
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按条件",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "添加日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_1"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "省",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_2"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 3,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "市",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_3"]
        }, {
          "children": [{
            "field": [{
              "desc": "类型一",
              "key": "type",
              "value": "0"
            }, {
              "desc": "类型二",
              "key": "type",
              "value": "1"
            }],
            "multiple": false,
            "type": "select"
          }],
          "id": 4,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "区",
          "type": 3,
          "remoteFunc": ["modelConditionFunc_4"]
        }],
        "type": "searchTemplate"
      },
      remoteFunc: {
        modelConditionFunc_2: function modelConditionFunc_2(tag, relationData, resolve) {
          //筛选条件数据
          areaList({
            depth: 1
          }).then(function _callee(res) {
            var data, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [];
                    console.log('res', res);

                    for (i in res) {
                      data.push({
                        key: 'province',
                        value: res[i].name,
                        desc: res[i].name
                      });
                    }

                    console.log('data', data);
                    resolve(tag, [data]);

                  case 5:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_3: function modelConditionFunc_3(tag, relationData, resolve) {
          //筛选条件数据
          areaList({
            depth: 2
          }).then(function _callee2(res) {
            var data, i, j;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      for (j in res[i].children) {
                        data.push({
                          key: 'city',
                          value: res[i].children[j].name,
                          desc: res[i].children[j].name
                        });
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_4: function modelConditionFunc_4(tag, relationData, resolve) {
          //筛选条件数据
          areaList({
            depth: 3
          }).then(function _callee3(res) {
            var data, i, j, k, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res) {
                      for (j in res[i].children) {
                        for (k in res[i].children[j].children) {
                          item = res[i].children[j].children[k];
                          data.push({
                            key: 'district',
                            value: item.name,
                            desc: item.name
                          });
                        }
                      }
                    }

                    resolve(tag, [data]);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
    this.search = this.$refs.ref_1001;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      agentList(params).then(function _callee4(res) {
        var i, mark, j, item;
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                for (i in res.rows) {
                  mark = '';

                  for (j in res.rows[i].t_agent_products) {
                    item = res.rows[i].t_agent_products[j].t_product;

                    if (item) {
                      mark += "<div>" + item.code + item.store_name + "</div>";
                    }
                  }

                  res.rows[i].product = mark;
                }

                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 2:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      } else {
        params.pid = this.curCity;
        params.layer = 2;
      }

      areaAdd(params).then(function _callee5(res) {
        return regeneratorRuntime.async(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context5.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      areaDel({
        id: ids
      }).then(function _callee6(res) {
        return regeneratorRuntime.async(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context6.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      var _this2 = this;

      //点击列表头部按钮回调
      this.$refs['areaHandle'].show(function () {
        _this2.table.reloadData();
      });
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};