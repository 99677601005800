import "core-js/modules/es.array.splice";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { agentHandle, agentList, areaAdd, areaList, memberMove, memberRepairMoney, productList } from '../../../../api/apis';
export default {
  data: function data() {
    return {
      dialogVisible: false,
      productList: [],
      areaList1: [],
      areaList2: [],
      areaList3: [],
      isSearch: false,
      form: {
        number: '',
        product: [],
        agent: []
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    productList({
      page: 1,
      limit: 1000,
      where: {
        is_main: 1
      }
    }).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this.productList = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
    areaList({}).then(function _callee2(res) {
      var tmp, i, j, _i;

      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _this.areaList1 = res;
              tmp = _this.$tempModel.deepCopy(res);

              for (i in tmp) {
                for (j in tmp[i].children) {
                  delete tmp[i].children[j].children;
                }
              }

              _this.areaList2 = tmp;
              tmp = _this.$tempModel.deepCopy(res);

              for (_i in tmp) {
                delete tmp[_i].children;
              }

              _this.areaList3 = tmp;

            case 7:
            case "end":
              return _context2.stop();
          }
        }
      });
    });
  },
  methods: {
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.isSearch = false;
      this.form = {
        number: '',
        product: [],
        agent: []
      };
    },
    areaListByLevel: function areaListByLevel(level) {
      if (level === 1) {
        return this.areaList1;
      } else if (level === 2) {
        return this.areaList2;
      } else {
        return this.areaList3;
      }
    },
    clickAddArea: function clickAddArea() {
      this.form.agent.push({
        level: 1,
        region: []
      });
    },
    clickDelArea: function clickDelArea(index) {
      this.form.agent.splice(index, 1);
    },
    clickSearch: function clickSearch() {
      var _this2 = this;

      agentList({
        where: {
          number: this.form.number
        }
      }).then(function _callee3(res) {
        var tmp, i, item, dic;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                tmp = [];

                for (i in res.rows) {
                  item = res.rows[i];
                  dic = {
                    level: item.level,
                    region: []
                  };

                  if (item.level === 1) {
                    dic.region = [item.province, item.city, item.district];
                  } else if (item.level === 2) {
                    dic.region = [item.province, item.city];
                  } else if (item.level === 3) {
                    dic.region = [item.province];
                  }

                  tmp.push(dic);
                }

                _this2.form.agent = tmp;
                _this2.isSearch = true;

              case 4:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      console.log('this.form', this.form);

      if (this.form.number === '') {
        this.$message({
          message: '请输入会员编号',
          type: 'error'
        });
        return;
      } // if (this.form.agent.length <= 0){
      //   this.$message({message:'请设置区域',type:'error'})
      //   return
      // }


      var params = this.$tempModel.deepCopy(this.form);

      for (var i in params.agent) {
        var item = params.agent[i];

        if (item.level === 1) {
          item.province = item.region[0];
          item.city = item.region[1];
          item.district = item.region[2];
        } else if (item.level === 2) {
          item.province = item.region[0];
          item.city = item.region[1];
          item.district = '';
        } else if (item.level === 3) {
          item.province = item.region[0];
          item.city = '';
          item.district = '';
        }
      }

      console.log('params', params);
      this.$g.fun.startLoading();
      agentHandle(params).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _this3.$message({
                  message: '设置成功',
                  type: 'success'
                });

                _this3.dialogVisible = false;

                if (_this3.finish) {
                  _this3.finish();
                }

                _this3.$g.fun.endLoading();

              case 4:
              case "end":
                return _context4.stop();
            }
          }
        });
      }).catch(function () {
        _this3.$g.fun.endLoading();
      });
    }
  }
};