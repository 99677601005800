var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { size: "mini" },
          on: { click: _vm.clickReset }
        },
        [_vm._v("重置数据")]
      ),
      _c("condition-group-cell", {
        attrs: {
          data: _vm.relationCondition,
          "condition-list": _vm.conditionList,
          parent: null,
          index: 0,
          "grade-list": _vm.gradeList,
          "level-list": _vm.levelList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }