import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
export default {
  name: 'baseConfig',
  data: function data() {
    return {
      colors: [{
        title: '墨黑',
        name: 'black',
        color: '#333333',
        tabList: ['/static/tab-home-black.png', '/static/tab-cate-black.png', '/static/tab-cart-black.png', '/static/tab-my-black.png']
      }, {
        title: '粉色',
        name: 'rf',
        color: '#fa436a',
        tabList: ['/static/tab-home-rf.png', '/static/tab-cate-rf.png', '/static/tab-cart-rf.png', '/static/tab-my-rf.png']
      }, {
        title: '嫣红',
        name: 'red',
        color: '#e54d42',
        tabList: ['/static/tab-home-red.png', '/static/tab-cate-red.png', '/static/tab-cart-red.png', '/static/tab-my-red.png']
      }, {
        title: '桔橙',
        name: 'orange',
        color: '#f37b1d',
        tabList: ['/static/tab-home-orange.png', '/static/tab-cate-orange.png', '/static/tab-cart-orange.png', '/static/tab-my-orange.png']
      }, {
        title: '明黄',
        name: 'yellow',
        color: '#fbbd08',
        tabList: ['/static/tab-home-yellow.png', '/static/tab-cate-yellow.png', '/static/tab-cart-yellow.png', '/static/tab-my-yellow.png']
      }, {
        title: '橄榄',
        name: 'olive',
        color: '#8dc63f',
        tabList: ['/static/tab-home-olive.png', '/static/tab-cate-olive.png', '/static/tab-cart-olive.png', '/static/tab-my-olive.png']
      }, {
        title: '森绿',
        name: 'green',
        color: '#39b54a',
        tabList: ['/static/tab-home-green.png', '/static/tab-cate-green.png', '/static/tab-cart-green.png', '/static/tab-my-green.png']
      }, {
        title: '天青',
        name: 'cyan',
        color: '#1cbbb4',
        tabList: ['/static/tab-home-cyan.png', '/static/tab-cate-cyan.png', '/static/tab-cart-cyan.png', '/static/tab-my-cyan.png']
      }, {
        title: '海蓝',
        name: 'blue',
        color: '#0081ff',
        tabList: ['/static/tab-home-blue.png', '/static/tab-cate-blue.png', '/static/tab-cart-blue.png', '/static/tab-my-blue.png']
      }, {
        title: '姹紫',
        name: 'purple',
        color: '#6739b6',
        tabList: ['/static/tab-home-purple.png', '/static/tab-cate-purple.png', '/static/tab-cart-purple.png', '/static/tab-my-purple.png']
      }, {
        title: '木槿',
        name: 'mauve',
        color: '#9c26b0',
        tabList: ['/static/tab-home-mauve.png', '/static/tab-cate-mauve.png', '/static/tab-cart-mauve.png', '/static/tab-my-mauve.png']
      }, {
        title: '桃粉',
        name: 'pink',
        color: '#e03997',
        tabList: ['/static/tab-home-pink.png', '/static/tab-cate-pink.png', '/static/tab-cart-pink.png', '/static/tab-my-pink.png']
      }, {
        title: '棕褐',
        name: 'brown',
        color: '#a5673f',
        tabList: ['/static/tab-home-brown.png', '/static/tab-cate-brown.png', '/static/tab-cart-brown.png', '/static/tab-my-brown.png']
      }, {
        title: '玄灰',
        name: 'grey',
        color: '#8799a3',
        tabList: ['/static/tab-home-grey.png', '/static/tab-cate-grey.png', '/static/tab-cart-grey.png', '/static/tab-my-grey.png']
      }, {
        title: '草灰',
        name: 'gray',
        color: '#aaaaaa',
        tabList: ['/static/tab-home-gray.png', '/static/tab-cate-gray.png', '/static/tab-cart-gray.png', '/static/tab-my-gray.png']
      }],
      colorItem: 'colorItem',
      colorItemSelect: 'colorItemSelect',
      config: {
        '3': 0,
        numberPrefix: '',
        numberNum: 8,
        initPassword: '',
        juheExpress: '',
        juheSMS: '',
        smsLogin: 0,
        posterShare: 0,
        payMode: ['balance'],
        expressFee: 0,
        orderFreeExpress: 0,
        secondVerify: 0,
        serviceTel: '',
        webTitle: '',
        isRegOpen: 0,
        fiveForOne: 0,
        //限制
        isFiveForOneOpen: 0,
        isEditProfile: 0,
        isMemberCancelOrder: 0,
        isRechargeBuy2: 0,
        isRegIdCard: 0,
        isRegSMS: 0,
        deleteUserByCancelOrder: 0,
        usePhoneBusiness: 0,
        fxExpressFee: 0,
        fxFreeExpress: 0,
        openFuXiaoShop: 0,
        showFuXiaoMoney: 0,
        showRechargeMoney: 0,
        idCardNum: 999999,
        marketShowType: 0,
        themeColor: '',
        accountTransfer: [],
        fxLimitDate: [],
        fxLimitMoney: 0,
        regAgeLimit: 60,
        logDays: 0,
        orderLimitDate: 0,
        orderLimitMoney: 0,
        marketNumType: 0,
        povDivNum: 1,
        allowTransfer: 0,
        ex1: 0,
        ex2: 0,
        ex3: 0,
        ex4: 0,
        ex5: 0,
        ex6: 0,
        ex7: 0,
        ex8: 0,
        ex9: 0,
        ex10: 0,
        ex11: 0,
        ex12: 0,
        ex13: 0,
        ex14: 0,
        ex15: 0,
        exInput1: 0,
        exInput2: 0,
        exInput3: 0,
        exInput4: 0,
        exInput5: 0,
        exInput6: 0,
        exInput7: 0,
        exInput8: 0,
        exInput9: 0,
        exInput10: 0,
        exInput11: 0,
        exInput12: 0,
        exInput13: 0,
        exInput14: 0,
        exInput15: 0
      },
      configDes: {},
      exArray: {},
      exInputArray: {}
    };
  },
  mounted: function mounted() {
    var _this = this;

    for (var i = 0; i < 16; i++) {
      this.exArray['ex' + i] = '';
    }

    for (var _i = 0; _i < 16; _i++) {
      this.exInputArray['exInput' + _i] = '';
    }

    var params = {
      filed: []
    };

    for (var key in this.config) {
      params.filed.push(key);
    }

    systemWebConfig(params).then(function _callee(res) {
      var _i2, item, value;

      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              for (_i2 in res) {
                _this.switchList = res;
                item = res[_i2];
                value = item.value;

                if (item.type === 'int') {
                  value = parseInt(item.value);
                }

                if (_this.config.hasOwnProperty(item.id)) {
                  _this.config[item.id] = value;
                }

                if (_this.exArray.hasOwnProperty(item.id)) {
                  _this.exArray[item.id] = item.name;
                }

                if (_this.exInputArray.hasOwnProperty(item.id)) {
                  _this.exInputArray[item.id] = item.name;
                  _this.configDes[item.id] = item.desc;
                }
              }

              console.log(_this.config);

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    isShowExInput: function isShowExInput(key) {
      if (this.exInputArray[key] === '') {
        return false;
      }

      return true;
    },
    isShowEx: function isShowEx(key) {
      console.log('aaaa', this.exArray, key, this.exArray[key], this.exArray[key] === '');

      if (this.exArray[key] === '') {
        return false;
      }

      return true;
    },
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var tmp = this.$tempModel.deepCopy(this.switchList);

      for (var i in tmp) {
        var item = tmp[i];

        if (this.config.hasOwnProperty(item.id)) {
          item.value = this.config[item.id];
        }
      }

      var params = tmp;
      console.log('params', params);
      systemWebConfigAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    clickSelectThemeColor: function clickSelectThemeColor(color) {
      console.log('aaaaa', color);
      this.config.themeColor = color;
    }
  }
};