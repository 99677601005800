import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { configBonusList, configGradeList, configLevelList, expectNumList, recommendHistoryList, recommendList } from '../../../../api/apis';
import { mapState, mapGetters, mapActions } from 'vuex';
import store from "@/store/index";
import setting from '../../../../setting';
export default {
  name: 'relationPv',
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      tableData: [],
      gradeList: [],
      levelList: [],
      activeNames: 'search',
      drawer: false,
      isRealTime: false,
      cacheChecked: {},
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "会员编号",
          "type": 2,
          "remoteFunc": []
        }, {
          "children": [{
            "field": [{
              "desc": "层级",
              "key": "layer",
              "value": ""
            }],
            "operator": [],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按层级",
          "type": 2,
          "remoteFunc": []
        }, {
          "title": "条件名称",
          "select": 0,
          "type": 1,
          "id": 5,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "custom-datetime-range",
            "title": "自定义",
            "field": []
          }],
          "remoteFunc": ["modelConditionFunc_5"]
        }],
        "type": "searchTemplate"
      },
      remoteFunc: {
        modelConditionFunc_5: function modelConditionFunc_5(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "pay_time",
            value: []
          }], [{
            key: "pay_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.search = this.$refs.ref_1000;
    configGradeList({}).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.gradeList = res.rows;

            case 1:
            case "end":
              return _context.stop();
          }
        }
      });
    });
    configLevelList({}).then(function _callee2(res) {
      return regeneratorRuntime.async(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _this2.levelList = res.rows;

            case 1:
            case "end":
              return _context2.stop();
          }
        }
      });
    });
  },
  methods: {
    load: function load(tree, treeNode, resolve) {
      setTimeout(function () {
        resolve([{
          id: 31,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          id: 32,
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }]);
      }, 1000);
    },
    listToTree: function listToTree(list, pid, parent) {
      var map = {};
      list.forEach(function (item) {
        if (!map[item.number]) {
          map[item.number] = item;
        }
      });
      list.forEach(function (item) {
        if (item.direct != parent) {
          map[item.direct].children ? map[item.direct].children.push(item) : map[item.direct].children = [item];
        }
      });
      return list.filter(function (item) {
        if (item.direct == parent) {
          return item;
        }
      });
    },
    reqTableData: function reqTableData(params, resolve) {
      var _this3 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
      }

      where.asc = 0;
      params.where = where;

      if (!params.where.hasOwnProperty('number') || !params.where.hasOwnProperty('layer')) {
        resolve({
          total: 0,
          data: []
        });
        return;
      }

      recommendList(params).then(function _callee3(res) {
        var i, tmp;
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                for (i in res) {
                  if (res[i].grade == null) {
                    res[i].grade = -1;
                  }
                }

                tmp = [];

                if (res.length > 0) {
                  tmp = _this3.listToTree(res, res[0].number, res[0].direct);
                }

                resolve({
                  total: 0,
                  data: tmp
                });

              case 4:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {
      //点击列表头部按钮回调
      this.drawer = true;
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};