import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { systemWebConfig, systemWebConfigAdd } from '../../../../api/apis';
export default {
  name: 'baseConfig',
  data: function data() {
    return {
      config: {
        '3': 0,
        numberPrefix: '',
        numberNum: 8,
        initPassword: '',
        juheExpress: '',
        juheSMS: '',
        smsLogin: 0,
        posterShare: 0,
        payMode: ['balance'],
        expressFee: 0,
        orderFreeExpress: 0,
        secondVerify: 0,
        serviceTel: '',
        webTitle: ''
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    var params = {
      filed: []
    };

    for (var key in this.config) {
      params.filed.push(key);
    }

    systemWebConfig(params).then(function _callee(res) {
      var i, item, value;
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              for (i in res) {
                _this.switchList = res;
                item = res[i];
                value = item.value;

                if (item.type === 'int') {
                  value = parseInt(item.value);
                }

                if (_this.config.hasOwnProperty(item.id)) {
                  _this.config[item.id] = value;
                }
              }

              console.log(_this.config);

            case 2:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      var tmp = this.$tempModel.deepCopy(this.switchList);

      for (var i in tmp) {
        var item = tmp[i];

        if (this.config.hasOwnProperty(item.id)) {
          item.value = this.config[item.id];
        }
      }

      var params = tmp;
      systemWebConfigAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this2.$message({
                  message: '保存成功',
                  type: 'success'
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};