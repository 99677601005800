import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { productTaskList, productTaskAdd, productTaskDel, productList, configLevelList, configGradeList } from '@/api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "type": "searchTemplate",
        "id": "id_1000",
        "del": false,
        "searchData": [{
          "title": "购买的产品",
          "select": 0,
          "type": 3,
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "type",
              "value": "0",
              "desc": "类型一"
            }, {
              "key": "type",
              "value": "1",
              "desc": "类型二"
            }]
          }],
          "remoteFunc": ["modelConditionFunc_0"]
        }, {
          "title": "赠品",
          "select": 0,
          "type": 3,
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "children": [{
            "type": "select",
            "multiple": false,
            "field": [{
              "key": "type",
              "value": "0",
              "desc": "类型一"
            }, {
              "key": "type",
              "value": "1",
              "desc": "类型二"
            }]
          }],
          "remoteFunc": ["modelConditionFunc_1"]
        }],
        "operateData": [{
          "title": "搜索",
          "type": "primary",
          "tag": "search",
          "icon": "el-icon-search"
        }, {
          "title": "导出",
          "type": "primary",
          "tag": "export",
          "icon": "el-icon-position"
        }],
        "isCollapse": false
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "满赠产品",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "main_product"
        }, {
          "label": "会员等级",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "不选代表全部等级适用",
          "groupId": "",
          "rules": [],
          "required": 0,
          "defaultValue": null,
          "prop": "target_grade"
        }, {
          "label": "会员资格",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "不选代表全部资格适用",
          "groupId": "",
          "rules": [],
          "required": 0,
          "rowAddField": "",
          "prop": "target_level"
        }, {
          "label": "满几",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [{
            "name": "integer"
          }],
          "required": 1,
          "prop": "fit_num"
        }, {
          "label": "赠几",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [{
            "name": "integer"
          }],
          "required": 1,
          "prop": "gift_num"
        }, {
          "label": "赠品",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "required": 1,
          "prop": "gift_product"
        }, {
          "label": "状态",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": null,
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "defaultValue": 1,
          "prop": "status"
        }, {
          "label": "添加时间",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": null,
          "type": "text",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "add_time"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {
          "target_grade": {
            "multiple": 1,
            "data": [{
              "key": 0,
              "value": "资格"
            }, {
              "key": 1,
              "value": "等级"
            }],
            "remote": true,
            "id": 0,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_target_grade"
          },
          "gift_product": {
            "multiple": 0,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 1,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_gift_product"
          },
          "main_product": {
            "multiple": 0,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 2,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_main_product"
          },
          "status": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "未启用"
            }, {
              "key": 1,
              "value": "启用"
            }],
            "remote": false,
            "id": 3,
            "relation": false,
            "relationProps": []
          },
          "target_level": {
            "multiple": 1,
            "data": [{
              "key": null,
              "value": ""
            }],
            "remote": true,
            "id": 4,
            "relation": false,
            "relationProps": [],
            "remoteFunc": "tableFunc_select_target_level"
          }
        },
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [{
          "title": "编辑",
          "type": "primary",
          "tag": "edit",
          "id": ""
        }, {
          "title": "删除",
          "type": "danger",
          "tag": "del",
          "id": ""
        }],
        "stripe": true,
        "border": true,
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "title": "商品满赠",
        "tableName": "",
        "isGroup": false,
        "isTree": false,
        "headerButton": [{
          "title": "添加",
          "type": "primary",
          "tag": "add",
          "id": ""
        }],
        "tableAutocompleteProps": {},
        "tableRadioProps": {}
      },
      remoteFunc: {
        modelConditionFunc_0: function modelConditionFunc_0(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'main_product',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        },
        modelConditionFunc_1: function modelConditionFunc_1(tag, relationData, resolve) {
          //筛选条件数据
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee2(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee2$(_context2) {
              while (1) {
                switch (_context2.prev = _context2.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: 'gift_product',
                        value: item.id,
                        desc: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, [data]);

                  case 2:
                  case "end":
                    return _context2.stop();
                }
              }
            });
          });
        },
        tableFunc_select_gift_product: function tableFunc_select_gift_product(tag, relationData, resolve) {
          //筛选项模型数组
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee3(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee3$(_context3) {
              while (1) {
                switch (_context3.prev = _context3.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.id,
                        value: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context3.stop();
                }
              }
            });
          });
        },
        tableFunc_select_main_product: function tableFunc_select_main_product(tag, relationData, resolve) {
          //筛选项模型数组
          var data = [];
          productList({
            page: 1,
            limit: 1000,
            where: {
              is_main: 1
            }
          }).then(function _callee4(res) {
            var i, item;
            return regeneratorRuntime.async(function _callee4$(_context4) {
              while (1) {
                switch (_context4.prev = _context4.next) {
                  case 0:
                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.id,
                        value: item.code + '-' + item.store_name
                      });
                    }

                    resolve(tag, data);

                  case 2:
                  case "end":
                    return _context4.stop();
                }
              }
            });
          });
        },
        tableFunc_select_target_grade: function tableFunc_select_target_grade(tag, relationData, resolve) {
          //筛选项模型数组
          configGradeList({
            page: 1,
            limit: 50
          }).then(function _callee5(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee5$(_context5) {
              while (1) {
                switch (_context5.prev = _context5.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context5.stop();
                }
              }
            });
          });
        },
        tableFunc_select_target_level: function tableFunc_select_target_level(tag, relationData, resolve) {
          //筛选项模型数组
          configLevelList({}).then(function _callee6(res) {
            var data, i, item;
            return regeneratorRuntime.async(function _callee6$(_context6) {
              while (1) {
                switch (_context6.prev = _context6.next) {
                  case 0:
                    data = [];

                    for (i in res.rows) {
                      item = res.rows[i];
                      data.push({
                        key: item.tag,
                        value: item.name
                      });
                    }

                    resolve(tag, data);

                  case 3:
                  case "end":
                    return _context6.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      productTaskList(params).then(function _callee7(res) {
        return regeneratorRuntime.async(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context7.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      productTaskAdd(params).then(function _callee8(res) {
        return regeneratorRuntime.async(function _callee8$(_context8) {
          while (1) {
            switch (_context8.prev = _context8.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context8.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      productTaskDel({
        id: ids
      }).then(function _callee9(res) {
        return regeneratorRuntime.async(function _callee9$(_context9) {
          while (1) {
            switch (_context9.prev = _context9.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context9.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};