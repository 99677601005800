var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "区域代理",
        visible: _vm.dialogVisible,
        width: "60%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "danger" }
            },
            [_vm._v("请先搜索会员，查看会员是否已设置过区域代理")]
          ),
          _c(
            "el-tag",
            {
              staticStyle: { "margin-bottom": "20px" },
              attrs: { type: "danger" }
            },
            [_vm._v("如果会员编号已存在，则会更新这个会员的代理区域")]
          ),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员编号" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.form.number,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "number", $$v)
                              },
                              expression: "form.number"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 10, offset: 1 } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.clickSearch }
                            },
                            [_vm._v("搜索会员")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._l(_vm.form.agent, function(item, index) {
                return _vm.isSearch
                  ? _c(
                      "el-card",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s("区域" + (index + 1)))
                            ]),
                            _c(
                              "el-button",
                              {
                                staticStyle: { float: "right" },
                                attrs: { size: "mini", type: "danger" },
                                on: { click: _vm.clickDelArea }
                              },
                              [_vm._v("删除")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择代理级别" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: item.level,
                                  callback: function($$v) {
                                    _vm.$set(item, "level", $$v)
                                  },
                                  expression: "item.level"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "区代", value: 1 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "市代", value: 2 }
                                }),
                                _c("el-option", {
                                  attrs: { label: "省代", value: 3 }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择区域" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                props: { value: "name", label: "name" },
                                clearable: true,
                                filterable: true,
                                options: _vm.areaListByLevel(item.level)
                              },
                              model: {
                                value: item.region,
                                callback: function($$v) {
                                  _vm.$set(item, "region", $$v)
                                },
                                expression: "item.region"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              }),
              _vm.isSearch
                ? _c(
                    "el-button",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.clickAddArea }
                    },
                    [_vm._v("添加代理区域")]
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }